// src/components/CharacterValidationTable.js

import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const CharacterValidationTable = ({ storyId, parsedLines, projectId, onValidationResult }) => {
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [detailedValidationResults, setDetailedValidationResults] = useState([]);
  const { user } = useUser();

  const handleValidateVoices = async () => {
    console.log('Starting voice and image validation process');
    setIsValidating(true);
    setError(null);
    setValidationMessage('');
    setDetailedValidationResults([]);

    try {
      console.log(`Sending validation request for story ${storyId}`);
      const response = await fetch(`${API_BASE_URL}/script-character-validation/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to validate character voices and images');
      }

      console.log('Validation response:', data);
      setValidationMessage(data.message);

      // Set detailed results if available
      if (data.detailedResults) {
        setDetailedValidationResults(data.detailedResults);
      }

      // Emit validation result to parent component
      if (onValidationResult) {
        onValidationResult({
          allValid: data.allValid,
          message: data.message,
          detailedResults: data.detailedResults
        });
      }

      // If the message indicates missing assignments, voices, or images, set an error
      if (!data.allValid) {
        setError("Some characters are missing assignments, voices, or images. Please check the detailed results below.");
      }

    } catch (err) {
      console.error('Validation error:', err);
      setError(err.message);
      
      // Report failed validation to parent
      if (onValidationResult) {
        onValidationResult({
          allValid: false,
          message: err.message,
          detailedResults: []
        });
      }
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <div>
      <div style={styles.projectActions}>
        <button
          onClick={handleValidateVoices}
          style={{
            ...styles.button,
            backgroundColor: isValidating ? '#ccc' : '#007BFF',
            cursor: isValidating ? 'not-allowed' : 'pointer',
          }}
          disabled={isValidating}
        >
          {isValidating ? 'Validating Characters...' : 'Validate Character Voices & Images'}
        </button>
      </div>
      {error && (
        <div style={{...styles.error, marginTop: '20px'}}>
          Error: {error}
        </div>
      )}
      {validationMessage && (
        <div style={{
          ...styles.formGroup,
          marginTop: '20px',
          padding: '10px',
          backgroundColor: error ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
          {validationMessage}
        </div>
      )}
      {detailedValidationResults.length > 0 && (
        <div style={{marginTop: '20px'}}>
          <h3 style={{...styles.formTitle, fontSize: '1.2rem'}}>Detailed Validation Results:</h3>
          <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '10px',
            backgroundColor: 'rgba(255,255,255,0.05)',
          }}>
            <thead>
              <tr>
                <th style={{...styles.tableHeader, width: '25%'}}>Script Character</th>
                <th style={{...styles.tableHeader, width: '25%'}}>Project Character</th>
                <th style={{...styles.tableHeader, width: '25%'}}>Voice Status</th>
                <th style={{...styles.tableHeader, width: '25%'}}>Image Status</th>
              </tr>
            </thead>
            <tbody>
              {detailedValidationResults.map((result, index) => (
                <tr key={index} style={{
                  backgroundColor: (result.voiceStatus.includes('Missing') || result.imageStatus.includes('Missing')) ? 
                    'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)'
                }}>
                  <td style={styles.tableCell}>{result.scriptCharacter}</td>
                  <td style={styles.tableCell}>{result.projectCharacter || 'Not Assigned'}</td>
                  <td style={{
                    ...styles.tableCell,
                    color: result.voiceStatus.includes('Missing') ? '#ff4444' : '#4caf50'
                  }}>{result.voiceStatus}</td>
                  <td style={{
                    ...styles.tableCell,
                    color: result.imageStatus.includes('Missing') ? '#ff4444' : '#4caf50'
                  }}>{result.imageStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Add these styles to styles.js if not already present
const tableStyles = {
  tableHeader: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    backgroundColor: '#333',
    color: 'white',
  },
  tableCell: {
    padding: '10px',
    borderBottom: '1px solid #444',
  },
};

Object.assign(styles, tableStyles);

export default CharacterValidationTable;