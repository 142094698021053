// src/pages/CharacterVoiceScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Volume2 } from 'lucide-react';

const CharacterVoiceScreen = () => {
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [voiceType, setVoiceType] = useState('');
  const [voiceGender, setVoiceGender] = useState('');
  const [voiceAge, setVoiceAge] = useState('');
  const [defaultVoices, setDefaultVoices] = useState([]);
  const [filteredVoices, setFilteredVoices] = useState([]);
  const [generatedAudioUrl, setGeneratedAudioUrl] = useState(null);
  const [selectedVoiceDetails, setSelectedVoiceDetails] = useState(null);
  const { user } = useUser();
  const { characterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Get referrer information from location state
  const referrer = location.state?.referrer;
  const storyId = location.state?.storyId;

  useEffect(() => {
    if (user.isAuthenticated) {
      loadInitialData();
    } else {
      navigate('/login');
    }
  }, [characterId, user.isAuthenticated, navigate]);

  useEffect(() => {
    if (voiceGender && voiceAge) {
      fetchFilteredVoices();
    }
  }, [voiceGender, voiceAge]);

  const loadInitialData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/voice_gen/load-voice-settings/${characterId}?user_id=${user.user_id}`
      );

      if (!response.ok) {
        throw new Error('Failed to load voice settings');
      }

      const data = await response.json();
      
      setCharacter(data.character);
      setDefaultVoices(data.defaultVoices);

      if (data.currentSettings) {
        const settings = data.currentSettings;
        const gender = settings.voice_gender?.toLowerCase() || 'male';
        const age = settings.voice_age?.toLowerCase().replace('-', '_') || 'middle_aged';
        
        setVoiceGender(gender);
        setVoiceAge(age);

        // Immediately filter voices based on gender and age
        const filtered = data.defaultVoices.filter(voice => 
          voice.gender.toLowerCase() === gender &&
          voice.age.toLowerCase().replace('-', '_') === age
        );
        setFilteredVoices(filtered);
        
        // Find the saved voice in filtered voices
        const savedVoice = filtered.find(v => v.voice_id === settings.voice_id);
        if (savedVoice) {
          setVoiceType(savedVoice.voice_id);
          setSelectedVoiceDetails(savedVoice);
          setGeneratedAudioUrl(settings.sample_audio_path ? null : savedVoice.preview_url);
        } else if (filtered.length > 0) {
          // If saved voice not in filtered list, select first available
          setVoiceType(filtered[0].voice_id);
          setSelectedVoiceDetails(filtered[0]);
          setGeneratedAudioUrl(filtered[0].preview_url);
        }
      } else {
        // Set defaults for new characters
        setVoiceGender('male');
        setVoiceAge('middle_aged');
        
        // Filter voices for default gender and age
        const filtered = data.defaultVoices.filter(voice => 
          voice.gender.toLowerCase() === 'male' &&
          voice.age.toLowerCase().replace('-', '_') === 'middle_aged'
        );
        setFilteredVoices(filtered);
        
        if (filtered.length > 0) {
          setVoiceType(filtered[0].voice_id);
          setSelectedVoiceDetails(filtered[0]);
          setGeneratedAudioUrl(filtered[0].preview_url);
        }
      }
    } catch (err) {
      console.error('Error loading initial data:', err);
      setError('Failed to load voice settings');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilteredVoices = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/voice_gen/get-filtered-voices?user_id=${user.user_id}&gender=${voiceGender}&age=${voiceAge}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch filtered voices');
      }

      const data = await response.json();
      setFilteredVoices(data.voices);

      // If current voice isn't in filtered list, select first available
      const currentVoiceStillValid = data.voices.some(v => v.voice_id === voiceType);
      if (!currentVoiceStillValid && data.voices.length > 0) {
        setVoiceType(data.voices[0].voice_id);
        setSelectedVoiceDetails(data.voices[0]);
        setGeneratedAudioUrl(data.voices[0].preview_url);
        await saveVoiceSettings(data.voices[0], voiceGender, voiceAge);
      } else if (!currentVoiceStillValid) {
        setVoiceType('');
        setSelectedVoiceDetails(null);
        setGeneratedAudioUrl(null);
      }
    } catch (err) {
      console.error('Error fetching filtered voices:', err);
      setError('Failed to load voice options');
    }
  };

  const saveVoiceSettings = async (selectedVoice, gender, age) => {
    try {
      await fetch(`${API_BASE_URL}/voice_gen/save-default-voice-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          character_id: characterId,
          voice_id: selectedVoice.voice_id,
          preview_url: selectedVoice.preview_url,
          name: selectedVoice.name,
          voice_gender: gender.toLowerCase(),
          voice_age: age.toLowerCase().replace('-', '_')
        }),
      });
    } catch (err) {
      console.error('Error saving voice settings:', err);
    }
  };

  const handleGenderChange = async (e) => {
    const newGender = e.target.value.toLowerCase();
    setVoiceGender(newGender);
  };

  const handleAgeChange = async (e) => {
    const newAge = e.target.value.toLowerCase().replace('-', '_');
    setVoiceAge(newAge);
  };

  const handleVoiceTypeChange = async (e) => {
    const newVoiceType = e.target.value;
    setVoiceType(newVoiceType);
    setGeneratedAudioUrl(null);

    const selectedVoice = filteredVoices.find(voice => voice.voice_id === newVoiceType);
    if (selectedVoice) {
      setSelectedVoiceDetails(selectedVoice);
      if (selectedVoice.preview_url) {
        setGeneratedAudioUrl(selectedVoice.preview_url);
        await saveVoiceSettings(selectedVoice, voiceGender, voiceAge);
      }
    } else {
      setSelectedVoiceDetails(null);
    }
  };

  // Determine where the back button should link to
  const getBackLink = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return `/story/${storyId}/script-media`;
    }
    return `/character/${characterId}`;
  };

  // Determine the back button text
  const getBackLinkText = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return <>&lt;&lt; Back to Script Media</>;
    }
    return <>&lt;&lt; Back to Character</>;
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!character) return <div style={styles.container}>Character not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={getBackLink()} style={styles.link}>
          {getBackLinkText()}
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Voice Selection for {character.name}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Select voice gender:</label>
        <select
          value={voiceGender}
          onChange={handleGenderChange}
          style={{
            ...styles.formInput,
            width: '200px'
          }}
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Select voice age:</label>
        <select
          value={voiceAge}
          onChange={handleAgeChange}
          style={{
            ...styles.formInput,
            width: '200px'
          }}
        >
          <option value="young">Young</option>
          <option value="middle_aged">Middle-aged</option>
          <option value="old">Old</option>
        </select>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Select voice:</label>
        <select
          value={voiceType}
          onChange={handleVoiceTypeChange}
          style={{
            ...styles.formInput,
            width: '200px'
          }}
        >
          {filteredVoices.length === 0 ? (
            <option value="">No matching voices available</option>
          ) : (
            filteredVoices.map((voice) => (
              <option key={voice.id} value={voice.voice_id}>{voice.name}</option>
            ))
          )}
        </select>

        {selectedVoiceDetails && (
          <div style={{
            marginTop: '10px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            padding: '12px',
            borderRadius: '4px'
          }}>
            <p style={{
              color: '#fff',
              margin: 0,
              fontSize: '0.95rem',
              lineHeight: '1.5'
            }}>
              {selectedVoiceDetails.accent ? 
                `${selectedVoiceDetails.accent.charAt(0).toUpperCase() + selectedVoiceDetails.accent.slice(1)} accent. ` : ''}
              {selectedVoiceDetails.description ?
                `${selectedVoiceDetails.description.charAt(0).toUpperCase() + selectedVoiceDetails.description.slice(1)}. ` : ''}
              {selectedVoiceDetails.use_case ?
                `Example use: ${selectedVoiceDetails.use_case.charAt(0).toUpperCase() + selectedVoiceDetails.use_case.slice(1)}.` : ''}
            </p>
          </div>
        )}
      </div>

      {generatedAudioUrl && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <label style={{...styles.label, marginBottom: '10px'}}>
            Voice Sample:
          </label>
          <audio
            controls
            style={{width: '100%'}}
            src={generatedAudioUrl}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default CharacterVoiceScreen;