// src/pages/NewCharacterScreen.js

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { useUser } from '../UserContext';
import { Pencil } from 'lucide-react';

const NewCharacterScreen = () => {
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [characterName, setCharacterName] = useState('');
  const [isEditingName, setIsEditingName] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get referrer information from location state
  const referrer = location.state?.referrer;
  const storyId = location.state?.storyId;

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!user.isAuthenticated) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/project/${projectId}/check-and-fetch?user_id=${user.user_id}`);
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch project');
        }

        const data = await response.json();
        setProjectName(data.project.name || 'Unnamed Project');
      } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId, user.isAuthenticated, user.user_id, navigate]);

  const handleNameChange = (e) => {
    setCharacterName(e.target.value);
  };

  const handleNameSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          project_id: projectId,
          name: characterName
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `HTTP error ${response.status}`);
      }

      // If we came from script media screen, go back there
      if (referrer === 'scriptMedia' && storyId) {
        navigate(`/story/${storyId}/script-media`);
      } else {
        // Otherwise go to project page as before
        navigate(`/project/${projectId}`);
      }
    } catch (err) {
      console.error('Error creating character:', err);
      setError(`Failed to create character: ${err.message}`);
    }
  };

  // Determine where the back button should link to
  const getBackLink = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return `/story/${storyId}/script-media`;
    }
    return `/project/${projectId}`;
  };

  // Determine the back button text
  const getBackLinkText = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return <>&lt;&lt; Back to Script Media</>;
    }
    return <>&lt;&lt; Back to Character</>;
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;

  return (
      <div style={styles.container}>
        <div style={styles.linkContainer}>
          <Link to={getBackLink()} style={styles.link}>
            {getBackLinkText()}
          </Link>
        </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Add New Character to {projectName}
      </h1>
      
      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          <button
            onClick={() => setIsEditingName(!isEditingName)}
            style={{...styles.button, ...styles.editButton}}
          >
            <Pencil size={16} />
          </button>
          <label style={styles.label}>Character Name:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingName ? (
            <>
              <input
                value={characterName}
                onChange={handleNameChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter character name"
              />
              <button 
                onClick={handleNameSave} 
                style={{...styles.button, marginTop: '10px'}}
                disabled={!characterName.trim()}
              >
                Save
              </button>
              <button 
                onClick={() => navigate(getBackLink())}
                style={{...styles.button, ...styles.cancelButton, marginTop: '10px', marginLeft: '10px'}}
              >
                Cancel
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {characterName || 'Unnamed Character'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCharacterScreen;