// src/components/GenerateShotImages.js
import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'lucide-react';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const GenerateShotImages = ({ storyId, userId, onGenerationComplete }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const eventSourceRef = useRef(null);

  // Clean up event source on unmount
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const generateShotImagePrompts = async () => {
    setIsGenerating(true);
    setError(null);
    setStats(null);
    setStatusMessage('Initializing image prompt generation...');
    
    try {
      // 1. Start generation process
      const response = await fetch(`${API_BASE_URL}/script-shot-images/generate/${storyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId
        }),
      });

      // Log the response status for debugging
      console.log('API Response Status:', response.status);
      
      let errorData;
      try {
        errorData = await response.json();
        console.log('API Response Data:', errorData);
      } catch (jsonError) {
        console.error('Error parsing JSON response:', jsonError);
        throw new Error('Invalid response from server');
      }

      if (!response.ok) {
        throw new Error(errorData.error || 'Failed to start image prompt generation');
      }

      // 2. Set up SSE for progress updates
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const eventSource = new EventSource(
        `${API_BASE_URL}/script-shot-images/progress/${storyId}?user_id=${userId}`
      );
      
      eventSourceRef.current = eventSource;
      
      eventSource.onmessage = (event) => {
        try {
          console.log('Raw SSE data:', event.data);
          
          // Handle case where the raw data might not be valid JSON
          let data;
          try {
            data = JSON.parse(event.data);
          } catch (jsonError) {
            console.error('Failed to parse SSE data:', jsonError, 'Raw data:', event.data);
            setError(`Error processing server update: Invalid data format`);
            eventSource.close();
            eventSourceRef.current = null;
            setIsGenerating(false);
            return;
          }
          
          console.log('Received SSE update:', data);

          if (data.complete) {
            console.log('Generation complete, closing event source');
            eventSource.close();
            eventSourceRef.current = null;
            setIsGenerating(false);
            setStatusMessage('All image prompts have been generated successfully.');
            
            // Call the callback to refresh data
            if (onGenerationComplete) {
              onGenerationComplete();
            }
            return;
          }

          if (data.error) {
            console.error('Error in SSE:', data.error);
            setError(data.error);
            eventSource.close();
            eventSourceRef.current = null;
            setIsGenerating(false);
            return;
          }

          // Update stats if included
          if (data.stats) {
            setStats(data.stats);
            
            // Update status message based on stats
            const { total_lines, processed_lines, completed_lines, skipped_lines, error_lines } = data.stats;
            if (total_lines > 0) {
              const percent = Math.round((processed_lines / total_lines) * 100);
              setStatusMessage(`Processing: ${percent}% complete (${completed_lines} generated, ${skipped_lines} skipped, ${error_lines} errors)`);
            }
          }

          // Update for specific line
          if (data.line_number) {
            const lineMsg = `Line ${data.line_number}: ${data.status}${data.message ? ` - ${data.message}` : ''}`;
            console.log(lineMsg);
            // Could add individual line updates to UI if desired
          }
        } catch (err) {
          console.error('Error processing SSE message:', err);
        }
      };

      eventSource.onerror = (err) => {
        console.error('SSE error:', err);
        eventSource.close();
        eventSourceRef.current = null;
        setIsGenerating(false);
        
        // Check if prompts were being generated before the error
        if (stats && stats.completed_lines > 0) {
          setError(`Connection lost, but ${stats.completed_lines} prompts were generated. Check the Shot Media table.`);
        } else {
          setError('Lost connection to server. The process may continue in the background.');
        }
      };

    } catch (err) {
      console.error('Error starting generation:', err);
      
      // Check if the error message is just the story ID (which indicates a backend issue)
      if (err.message === storyId) {
        setError(`Server error processing story. The process may have started but encountered an issue. Please check the Shot Media table for any generated prompts.`);
      } else {
        setError(err.message);
      }
      
      setIsGenerating(false);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
      marginTop: '20px'
    }}>
      <button
        onClick={generateShotImagePrompts}
        style={{
          ...styles.button,
          backgroundColor: '#007BFF',
          fontSize: '1.1rem',
          padding: '12px 24px',
          opacity: isGenerating ? 0.7 : 1,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '10px'
        }}
        disabled={isGenerating}
      >
        {isGenerating && <Loader size={16} />}
        {isGenerating ? 'Generating Image Prompts...' : 'Generate Missing Shot Image Prompts'}
      </button>
      
      {/* Status message */}
      {statusMessage && (
        <div style={{
          fontSize: '0.9rem',
          color: '#666',
          textAlign: 'center',
          marginBottom: '10px'
        }}>
          {statusMessage}
        </div>
      )}
      
      {/* Error message */}
      {error && (
        <div style={{
          fontSize: '0.9rem',
          color: '#FF4444',
          textAlign: 'center',
          marginBottom: '10px'
        }}>
          Error: {error}
        </div>
      )}
      
      {/* Results stats */}
      {stats && !isGenerating && (
        <div style={{
          fontSize: '0.9rem',
          color: '#4CAF50',
          textAlign: 'center',
          padding: '10px',
          backgroundColor: 'rgba(76,175,80,0.1)',
          borderRadius: '4px',
          marginBottom: '10px'
        }}>
          Processed {stats.total_lines} shot lines: {' '}
          {stats.completed_lines} prompts generated, {stats.skipped_lines} skipped, {stats.error_lines} errors.
        </div>
      )}
    </div>
  );
};

export default GenerateShotImages;