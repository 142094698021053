// src/components/ScriptCharactersTable.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Volume2, Image, CheckCircle, FileText, AlertCircle, Loader } from 'lucide-react';

const ScriptCharactersTable = ({ storyId, parsedLines, projectId }) => {
  const [scriptCharacters, setScriptCharacters] = useState([]);
  const [projectCharacters, setProjectCharacters] = useState([]);
  const [mappings, setMappings] = useState({});
  const [voiceSettings, setVoiceSettings] = useState({});
  const [imageSettings, setImageSettings] = useState({});
  const [characterDescriptions, setCharacterDescriptions] = useState({});
  const [matchTypes, setMatchTypes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const uniqueCharacters = [...new Set(
      parsedLines
        .filter(line => line.character_name !== "Non-speaking part")
        .map(line => line.character_name)
    )];
    setScriptCharacters(uniqueCharacters);

    const initializeData = async () => {
      await fetchProjectCharacters();
      await fetchExistingMappings();
    };

    initializeData();
  }, [parsedLines, projectId, storyId, user.user_id]);

  useEffect(() => {
    const fetchAllSettings = async () => {
      const voiceSettingsData = {};
      const imageSettingsData = {};
      const characterDescriptionsData = {};
      const mappedCharacterIds = Object.values(mappings).filter(Boolean);
      
      for (const characterId of mappedCharacterIds) {
        try {
          // Fetch voice settings
          const voiceResponse = await fetch(
            `${API_BASE_URL}/voice_gen/load-voice-settings/${characterId}?user_id=${user.user_id}`
          );
          
          if (voiceResponse.ok) {
            const data = await voiceResponse.json();
            if (data.currentSettings) {
              voiceSettingsData[characterId] = {
                name: data.currentSettings.name
              };
            }
          }

          // Fetch image settings and description
          const imageResponse = await fetch(
            `${API_BASE_URL}/image-gen/load-image-settings/${characterId}?user_id=${user.user_id}`
          );
          
          if (imageResponse.ok) {
            const data = await imageResponse.json();
            if (data.image_settings) {
              imageSettingsData[characterId] = data.image_settings;
              
              // Store the character description if it exists
              if (data.image_settings.image_description) {
                characterDescriptionsData[characterId] = data.image_settings.image_description;
              }
            }
          }
        } catch (err) {
          console.error(`Failed to load settings for character ${characterId}:`, err);
        }
      }
      
      setVoiceSettings(voiceSettingsData);
      setImageSettings(imageSettingsData);
      setCharacterDescriptions(characterDescriptionsData);
    };

    if (Object.keys(mappings).length > 0) {
      fetchAllSettings();
    }
  }, [mappings, user.user_id]);

  // Add useEffect to fetch match information
  useEffect(() => {
    const fetchMatchInfo = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/script-parsing/get-character-match-info/${storyId}?user_id=${user.user_id}`
        );
        
        if (!response.ok) throw new Error('Failed to fetch character match info');
        
        const data = await response.json();
        setMatchTypes(data.matchTypes || {});
      } catch (err) {
        console.error('Failed to load match info:', err);
      }
    };

    if (Object.keys(mappings).length > 0) {
      fetchMatchInfo();
    }
  }, [mappings, storyId, user.user_id]);

  // Enhanced fetchProjectCharacters with retry logic
  const fetchProjectCharacters = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/character/list?project_id=${projectId}&user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch project characters');
      }
      
      const data = await response.json();
      setProjectCharacters(data.characters || []);
      setError(null); // Clear any previous errors
      setRetryCount(0); // Reset retry count on success
      return data.characters || [];
    } catch (err) {
      console.error('Error fetching project characters:', err);
      
      // Check if we should retry
      if (retryCount < 3) {
        console.log(`Retrying character fetch (${retryCount + 1}/3) in ${Math.pow(2, retryCount)}s`);
        setError(`Fetching character data... Retry ${retryCount + 1}/3`);
        
        // Set timeout with exponential backoff
        const timeout = setTimeout(() => {
          setRetryCount(prev => prev + 1);
          fetchProjectCharacters();
        }, 1000 * Math.pow(2, retryCount));
        
        return [];
      } else {
        setError('Failed to load project characters after multiple attempts. Try refreshing the page.');
        setIsLoading(false);
        return [];
      }
    } finally {
      if (!error) {
        setIsLoading(false);
      }
    }
  };

  const fetchExistingMappings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/get-character-mappings/${storyId}?user_id=${user.user_id}`);
      if (!response.ok) throw new Error('Failed to fetch character mappings');
      const data = await response.json();
      
      if (Object.keys(data.mappings || {}).length === 0) {
        await getSuggestedMappings();
      } else {
        setMappings(data.mappings);
      }
    } catch (err) {
      console.error('Failed to load character mappings:', err);
      setError('Failed to load character mappings. Try refreshing the page.');
    } finally {
      setIsLoading(false);
    }
  };

  const getSuggestedMappings = async () => {
    try {
      const uniqueCharacters = [...new Set(
        parsedLines
          .filter(line => line.character_name !== "Non-speaking part")
          .map(line => line.character_name)
      )];

      const response = await fetch(`${API_BASE_URL}/script-parsing/suggest-character-mappings/${storyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          script_characters: uniqueCharacters,
          project_characters: projectCharacters
        }),
      });

      if (!response.ok) throw new Error('Failed to get character mapping suggestions');
      
      const data = await response.json();
      setMappings(data.mappings);
    } catch (err) {
      console.error('Error getting character mapping suggestions:', err);
    }
  };

  const handleCharacterSelect = async (scriptCharacter, selectedValue) => {
    if (selectedValue === 'create-new') {
      // Pass the storyId and 'scriptMedia' as referrer in navigation state
      navigate(`/new-character/${projectId}`, { 
        state: { 
          referrer: 'scriptMedia',
          storyId: storyId
        }
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/script-parsing/update-character-mapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId,
          script_character: scriptCharacter,
          project_character_id: selectedValue || null
        }),
      });

      if (!response.ok) throw new Error('Failed to update character mapping');
      
      setMappings(prev => ({
        ...prev,
        [scriptCharacter]: selectedValue || null
      }));
    } catch (err) {
      setError('Failed to update character mapping');
    }
  };

  const handleVoiceClick = (characterId) => {
    if (characterId) {
      // Pass the storyId and 'scriptMedia' as referrer in navigation state
      navigate(`/character-voice/${characterId}`, {
        state: {
          referrer: 'scriptMedia',
          storyId: storyId
        }
      });
    }
  };

  const handleImageClick = (characterId) => {
    if (characterId) {
      // Pass the storyId and 'scriptMedia' as referrer in navigation state
      navigate(`/character-image/${characterId}`, {
        state: {
          referrer: 'scriptMedia',
          storyId: storyId
        }
      });
    }
  };

  const getVoiceDisplay = (characterId) => {
    if (!characterId) return "No Character Selected";
    if (!voiceSettings[characterId]) return "No Voice";
    return voiceSettings[characterId].name || "Unnamed Voice";
  };

  const getImageDisplay = (characterId) => {
    if (!characterId) return "No Character Selected";
    if (!imageSettings[characterId]) return "No Image";
    
    const charName = imageSettings[characterId].characterName || "Character";
    
    if (imageSettings[characterId].hasImage) {
      return `${charName} Image`;
    } else {
      return "No Image";
    }
  };

  // Add helper function to get description display
  const getDescriptionDisplay = (characterId) => {
    if (!characterId) return "No Character Selected";
    if (!characterDescriptions[characterId]) return "No Description";
    
    // Return description with truncation for display
    const description = characterDescriptions[characterId];
    return description.length > 50 ? `${description.substring(0, 50)}...` : description;
  };

  // Add helper function to display match indicators
  const getMatchIndicator = (scriptChar) => {
    const matchInfo = matchTypes[scriptChar];
    if (!matchInfo || !matchInfo.auto_matched) return null;

    const tooltipText = matchInfo.match_type === 'exact' 
      ? 'Auto-matched (perfect match)' 
      : `Auto-matched (${Math.round(matchInfo.similarity_score * 100)}% similar)`;

    return (
      <div 
        title={tooltipText}
        style={{ 
          display: 'inline-flex', 
          alignItems: 'center',
          marginLeft: '8px',
          color: matchInfo.match_type === 'exact' ? '#4CAF50' : '#2196F3'
        }}
      >
        <CheckCircle size={16} />
      </div>
    );
  };

  // Add loading indicator
  if (isLoading) return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
        Script Characters:
      </h2>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
        backgroundColor: 'rgba(255,255,255,0.05)',
        borderRadius: '4px'
      }}>
        <Loader size={32} style={{ 
          color: '#2196F3',
          animation: 'spin 1s linear infinite',
          marginBottom: '16px'
        }} />
        <p>Loading character data...</p>
      </div>
    </div>
  );

  // Show error state with retry option
  if (error && !isLoading) return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
        Script Characters:
      </h2>
      <div style={{
        padding: '20px',
        backgroundColor: 'rgba(244,67,54,0.1)',
        borderRadius: '4px',
        textAlign: 'center'
      }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
          <AlertCircle size={24} style={{ color: '#F44336', marginRight: '8px' }} />
          <span>{error}</span>
        </div>
        <button 
          onClick={() => {
            setRetryCount(0);
            setError(null);
            fetchProjectCharacters();
          }}
          style={{
            ...styles.button,
            marginTop: '10px',
            backgroundColor: '#F44336',
            fontSize: '0.9rem',
            padding: '8px 16px'
          }}
        >
          Retry Now
        </button>
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
        Script Characters:
      </h2>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: 'rgba(255,255,255,0.05)',
        }}>
          <thead>
            <tr>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Script Character</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Related Project Character</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Description</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Voice</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
              }}>Image</th>
            </tr>
          </thead>
          <tbody>
            {scriptCharacters.map((scriptChar) => (
              <tr key={scriptChar}>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{scriptChar}</span>
                    {getMatchIndicator(scriptChar)}
                  </div>
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <select
                    value={mappings[scriptChar] || ''}
                    onChange={(e) => handleCharacterSelect(scriptChar, e.target.value)}
                    style={{
                      ...styles.formInput,
                      width: '100%',
                      backgroundColor: 'rgba(255,255,255,0.1)',
                    }}
                  >
                    <option value="">Select Character</option>
                    {projectCharacters.map((char) => (
                      <option key={char.id} value={char.id}>
                        {char.name}
                      </option>
                    ))}
                    <option value="create-new">Create New Character</option>
                  </select>
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  maxWidth: '250px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  <div 
                    title={characterDescriptions[mappings[scriptChar]] || "No description available"}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {mappings[scriptChar] && characterDescriptions[mappings[scriptChar]] && <FileText size={16} />}
                    <span>{getDescriptionDisplay(mappings[scriptChar])}</span>
                  </div>
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <div 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      cursor: mappings[scriptChar] ? 'pointer' : 'default',
                    }}
                    onClick={() => handleVoiceClick(mappings[scriptChar])}
                  >
                    {mappings[scriptChar] && <Volume2 size={16} />}
                    <span>{getVoiceDisplay(mappings[scriptChar])}</span>
                  </div>
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                }}>
                  <div 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      cursor: mappings[scriptChar] ? 'pointer' : 'default',
                      opacity: mappings[scriptChar] ? 1 : 0.7,
                    }}
                    onClick={() => mappings[scriptChar] && handleImageClick(mappings[scriptChar])}
                  >
                    {mappings[scriptChar] && <Image size={16} />}
                    <span
                      style={{
                        cursor: mappings[scriptChar] ? 'pointer' : 'default',
                        textDecoration: mappings[scriptChar] ? 'underline' : 'none',
                        color: mappings[scriptChar] ? '#007BFF' : 'inherit',
                      }}
                    >
                      {getImageDisplay(mappings[scriptChar])}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScriptCharactersTable;