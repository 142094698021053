// src/pages/ShotHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import ShotAudio from '../components/ShotAudio';
import ShotImage from '../components/ShotImage';
import ShotVideo from '../components/ShotVideo'; // Import the new ShotVideo component

const ShotHomeScreen = () => {
  // Original state variables
  const [shot, setShot] = useState(null);
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [permissionLevel, setPermissionLevel] = useState(null);
  
  // Image state variables - passed to ShotImage
  const [imagePrompt, setImagePrompt] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [storedImageUrl, setStoredImageUrl] = useState(null);
  
  // State variable for audio - needed for video generation
  const [audioUrl, setAudioUrl] = useState(null);
  
  // State variables for video - passed to ShotVideo
  const [videoUrl, setVideoUrl] = useState(null);
  const [storedVideoUrl, setStoredVideoUrl] = useState(null);
  
  // New state variable to store shot type
  const [shotType, setShotType] = useState(null);
  
  const { user } = useUser();
  const { dialogueId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchShotDetails();
    } else {
      navigate('/login');
    }
  }, [dialogueId, user.isAuthenticated, navigate]);

  const fetchShotDetails = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/shot/${dialogueId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch shot details');
      }
  
      const data = await response.json();
      setShot(data.shot);
      setStory(data.story);
      setPermissionLevel(data.permissionLevel);
      
      // Set image data if it exists in the response
      if (data.shot?.image_prompt) {
        setImagePrompt(data.shot.image_prompt);
      }
      
      // Check for stored_image_url first, then fall back to image_url
      if (data.shot?.stored_image_url) {
        setStoredImageUrl(data.shot.stored_image_url);
        setImageUrl(data.shot.stored_image_url);
      } else if (data.shot?.image_url) {
        setImageUrl(data.shot.image_url);
      }
  
      // Set audio URL if it exists in the response
      if (data.shot?.audio_url) {
        setAudioUrl(data.shot.audio_url);
      }
      
      // Set video URLs if they exist in the response
      if (data.shot?.stored_video_url) {
        setStoredVideoUrl(data.shot.stored_video_url);
        setVideoUrl(data.shot.stored_video_url);
      } else if (data.shot?.video_url) {
        setVideoUrl(data.shot.video_url);
      }
      
      // Set shot type from the API response or derive it if not present
      if (data.shot?.shot_type) {
        setShotType(data.shot.shot_type);
      } else {
        // Derive initial type based on character name
        const derivedType = data.shot.character_name === "Non-speaking part" ? "action" : "talking";
        setShotType(derivedType);
        
        // Save the derived type to the database if user has appropriate permissions
        if (data.permissionLevel === 'owner' || data.permissionLevel === 'editor') {
          updateShotType(derivedType);
        }
      }
      
      setIsLoading(false);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
      setIsLoading(false);
    }
  };

  // Function to update shot type in the database
  const updateShotType = async (newType) => {
    try {
      const response = await fetch(`${API_BASE_URL}/shot/${dialogueId}/update-type`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          shot_type: newType
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update shot type');
      }
      
      // Update local state
      setShotType(newType);
    } catch (err) {
      console.error('Error updating shot type:', err);
      setError(err.message || 'An error occurred while updating shot type');
    }
  };

  if (isLoading) return <div style={styles.container}>Loading shot details...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!shot) return <div style={styles.container}>Shot not found.</div>;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/story/${story.id}/script-media`} style={styles.link}>
          &lt;&lt; Back to Script Media
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Shot Details - Line {shot.line_number}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Story:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {story.title}
        </p>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Line Number:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {shot.line_number}
        </p>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Scene Location:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {shot.scene_location || 'No scene location specified'}
        </p>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Character:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {shot.character_name}
        </p>
      </div>

      {shot.stage_directions && (
        <div style={styles.formGroup}>
          <label style={styles.label}>Stage Directions:</label>
          <div style={{
            padding: '15px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            borderRadius: '8px',
            fontStyle: 'italic',
            textAlign: 'left'
          }}>
            {shot.stage_directions}
          </div>
        </div>
      )}

      <div style={styles.formGroup}>
        <label style={styles.label}>Dialogue:</label>
        <div style={{
          padding: '15px',
          backgroundColor: 'rgba(255,255,255,0.05)',
          borderRadius: '8px',
          textAlign: 'left'
        }}>
          {shot.dialogue_text}
        </div>
      </div>

      {/* Using ShotAudio component */}
      <ShotAudio
        storyId={story.id}
        lineNumber={shot.line_number}
        userId={user.user_id}
        initialAudioUrl={audioUrl}
        onAudioUrlChange={setAudioUrl}
        onError={setError}
      />

      {/* Using the ShotImage component */}
      <ShotImage
        dialogueId={dialogueId}
        userId={user.user_id}
        imagePrompt={imagePrompt}
        setImagePrompt={setImagePrompt}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        storedImageUrl={storedImageUrl}
        setStoredImageUrl={setStoredImageUrl}
        permissionLevel={permissionLevel}
        onError={setError}
      />

      {/* Using the ShotVideo component with updated props */}
      <ShotVideo
        dialogueId={dialogueId}
        userId={user.user_id}
        imageUrl={imageUrl}
        audioUrl={audioUrl}
        characterName={shot.character_name}
        initialVideoUrl={videoUrl}
        initialStoredVideoUrl={storedVideoUrl}
        initialShotType={shotType}
        permissionLevel={permissionLevel}
        onVideoUrlChange={setVideoUrl}
        onStoredVideoUrlChange={setStoredVideoUrl}
        onShotTypeChange={updateShotType}
        onError={setError}
      />
    </div>
  );
};

export default ShotHomeScreen;