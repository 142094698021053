// src/pages/StoryHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, PlusCircle, Volume2 } from 'lucide-react';

const StoryHomeScreen = () => {
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const { user } = useUser();
  const { storyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchStoryDetails();
    } else {
      navigate('/login');
    }
  }, [storyId, user.isAuthenticated, navigate]);

  const fetchStoryDetails = async () => {
    if (!user.user_id || !storyId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch story details');
      }

      const data = await response.json();
      setStory(data.story);
      setPermissionLevel(data.permissionLevel);
      setProjectId(data.projectId);
      setTitle(data.story.title || '');
      setMinutes(data.story.length?.minutes ?? 5);
      setSeconds(data.story.length?.seconds ?? 0);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/update-title`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          title 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update title');
      }

      setStory({ ...story, title });
      setIsEditingTitle(false);
    } catch (err) {
      console.error('Error updating title:', err);
      setError('Failed to update title. Please try again.');
    }
  };

  const handleLengthChange = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/story/${storyId}/update-length`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          minutes,
          seconds
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update story length');
      }
    } catch (err) {
      console.error('Error updating story length:', err);
      setError('Failed to update story length. Please try again.');
    }
  };

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setSeconds(15); // Only set to 15 if both minutes and seconds are 0
    }
    handleLengthChange();
  }, [minutes, seconds]);

  const handleDeleteStory = async () => {
    if (window.confirm('Are you sure you want to delete this story?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/story/delete`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            user_id: user.user_id, 
            story_id: storyId,
            project_id: projectId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to delete story');
        }

        navigate(`/project/${projectId}`);
      } catch (err) {
        console.error('Error deleting story:', err);
        setError('Failed to delete story. Please try again.');
      }
    }
  };

  if (isLoading) return <div style={styles.container}>Loading story...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!story) return <div style={styles.container}>Story not found.</div>;

  const canEditStory = permissionLevel === 'owner' || permissionLevel === 'editor';
  const canDeleteStory = permissionLevel === 'owner' || user.email === story.created_by;

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/project/${projectId}`} style={styles.link}>
          &lt;&lt; Back to Project Home
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {story.title || 'Untitled Story'}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Created By:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {story.created_by || 'Unknown'}
        </p>
      </div>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {canEditStory && (
            <button
              onClick={() => setIsEditingTitle(!isEditingTitle)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Story Title:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingTitle && canEditStory ? (
            <>
              <input
                value={title}
                onChange={handleTitleChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter story title"
              />
              <button onClick={handleTitleSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {story.title || 'Untitled Story'}
            </p>
          )}
        </div>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Length of Story:</label>
        <div style={styles.lengthSelectors}>
          <select 
            value={minutes}
            onChange={(e) => setMinutes(Number(e.target.value))}
            style={styles.lengthSelect}
            disabled={!canEditStory}
          >
            {[0, 1, 2, 3, 4, 5].map(value => (
              <option key={value} value={value}>{value}</option>
            ))}
          </select>
          <span style={styles.lengthLabel}>minutes</span>
          
          <select 
            value={seconds}
            onChange={(e) => setSeconds(Number(e.target.value))}
            style={styles.lengthSelect}
            disabled={!canEditStory}
          >
            {minutes === 0 ? (
              [15, 30, 45].map(value => (
                <option key={value} value={value}>{value.toString().padStart(2, '0')}</option>
              ))
            ) : (
              [0, 15, 30, 45].map(value => (
                <option key={value} value={value}>{value.toString().padStart(2, '0')}</option>
              ))
            )}
          </select>
          <span style={styles.lengthLabel}>seconds</span>
        </div>
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Logline:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {story.logline?.text || 'No logline available.'}
        </p>
        {canEditStory && (
          <Link 
            to={`/story/${storyId}/edit-logline`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>{story.logline?.text ? 'Edit' : 'Add'} Story Logline</span>
          </Link>
        )}
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Script:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {story.script?.text || 'No script available.'}
        </p>
        {canEditStory && (
          <Link 
            to={`/story/${storyId}/edit-script`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>{story.script?.text ? 'Edit' : 'Add'} Story Script</span>
          </Link>
        )}
        {story.script?.text && (
          <Link 
            to={`/story/${storyId}/script-media`} 
            style={{
              ...styles.link,
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#007BFF',
              marginTop: '10px',
            }}
          >
            <PlusCircle size={24} style={{marginRight: '10px'}} />
            <span>Generate Media</span>
          </Link>
        )}
      </div>

      {canDeleteStory && (
        <div style={styles.projectActions}>
          <button 
            style={{...styles.button, backgroundColor: '#ff4444'}}
            onClick={handleDeleteStory}
          >
            Delete Story
          </button>
        </div>
      )}
    </div>
  );
};

export default StoryHomeScreen;