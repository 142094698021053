// src/components/ScriptLocationsTable.js

import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil } from 'lucide-react';

const ScriptLocationsTable = ({ storyId, refreshTrigger = 0 }) => {
  const [sceneLocations, setSceneLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [editDescription, setEditDescription] = useState('');
  const { user } = useUser();
  
  useEffect(() => {
    const fetchSceneLocations = async () => {
      if (!storyId || !user.user_id) return;
      
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_BASE_URL}/script-locations/list/${storyId}?user_id=${user.user_id}`
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch scene locations');
        }
        
        const data = await response.json();
        setSceneLocations(data.scene_locations || []);
      } catch (err) {
        console.error('Error fetching scene locations:', err);
        setError('Failed to load scene locations');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSceneLocations();
  }, [storyId, user.user_id, refreshTrigger]); // Added refreshTrigger to trigger re-fetching
  
  const handleEditClick = (location) => {
    setEditingLocationId(location.id);
    setEditDescription(location.location_description || '');
  };
  
  const handleDescriptionChange = (e) => {
    setEditDescription(e.target.value);
  };
  
  const handleSaveDescription = async (locationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/script-locations/update-location-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId,
          location_id: locationId,
          location_description: editDescription
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update location description');
      }
      
      // Update local state with new description
      setSceneLocations(locations => 
        locations.map(loc => 
          loc.id === locationId 
            ? { ...loc, location_description: editDescription } 
            : loc
        )
      );
      
      // Exit edit mode
      setEditingLocationId(null);
    } catch (err) {
      console.error('Error updating location description:', err);
      setError('Failed to update location description');
    }
  };
  
  const handleCancelEdit = () => {
    setEditingLocationId(null);
  };
  
  if (isLoading) return <div style={styles.container}>Loading scene locations...</div>;
  if (error) return <div style={{...styles.error, marginTop: '20px'}}>{error}</div>;
  if (sceneLocations.length === 0) return null;
  
  return (
    <div style={{ marginTop: '40px', marginBottom: '40px' }}>
      <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
        Scene Locations:
      </h2>
      <div style={{overflowX: 'auto', width: '100%', maxWidth: '100%'}}>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: 'rgba(255,255,255,0.05)',
          tableLayout: 'fixed',
        }}>
          <thead>
            <tr>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
                width: '40%',
              }}>Location</th>
              <th style={{
                padding: '12px',
                textAlign: 'left',
                borderBottom: '2px solid #444',
                backgroundColor: '#333',
                width: '60%',
              }}>Location Description</th>
            </tr>
          </thead>
          <tbody>
            {sceneLocations.map((location) => (
              <tr key={location.id}>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  width: '40%',
                }}>{location.name}</td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  position: 'relative',
                  width: '60%',
                }}>
                  {editingLocationId === location.id ? (
                    <div style={{ width: '100%', paddingRight: '12px' }}>
                      <textarea
                        value={editDescription}
                        onChange={handleDescriptionChange}
                        style={{
                          ...styles.formInput,
                          width: '100%',
                          minHeight: '80px',
                          backgroundColor: 'rgba(255,255,255,0.1)',
                          color: '#fff',
                          boxSizing: 'border-box',
                        }}
                        placeholder="Enter location description"
                      />
                      <div style={{ marginTop: '10px' }}>
                        <button 
                          onClick={() => handleSaveDescription(location.id)} 
                          style={{...styles.button, marginRight: '10px'}}
                        >
                          Save
                        </button>
                        <button 
                          onClick={handleCancelEdit} 
                          style={{...styles.button, backgroundColor: '#666'}}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <div style={{ flex: 1 }}>
                        {location.location_description || 
                          <span style={{ color: '#777', fontStyle: 'italic' }}>No description available</span>
                        }
                      </div>
                      <button
                        onClick={() => handleEditClick(location)}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          padding: '4px',
                          marginLeft: '8px',
                          color: '#007BFF',
                        }}
                        title="Edit description"
                      >
                        <Pencil size={16} />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScriptLocationsTable;