// src/components/ShotImage.js

import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../config';
import { Image, Pencil } from 'lucide-react';
import { styles } from '../styles';

const ShotImage = ({ 
  dialogueId, 
  userId, 
  imagePrompt,
  setImagePrompt,
  imageUrl,
  setImageUrl,
  storedImageUrl,
  setStoredImageUrl,
  permissionLevel,
  onError
}) => {
  // Image editing state
  const [isEditingImagePrompt, setIsEditingImagePrompt] = useState(false);
  const [editedImagePrompt, setEditedImagePrompt] = useState(imagePrompt || '');
  
  // Image generation states
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [imageStorageStatus, setImageStorageStatus] = useState(null);
  
  // Refs for polling intervals and timeouts
  const pollIntervalRef = useRef(null);
  const pollTimeoutRef = useRef(null);

  // Effect to update local state when prop changes
  useEffect(() => {
    setEditedImagePrompt(imagePrompt || '');
  }, [imagePrompt]);

  // Clean up any intervals or timeouts when component unmounts
  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) clearInterval(pollIntervalRef.current);
      if (pollTimeoutRef.current) clearTimeout(pollTimeoutRef.current);
    };
  }, []);

  // Function to handle image prompt edit
  const handleImagePromptChange = (e) => {
    setEditedImagePrompt(e.target.value);
  };

  // Function to save the edited image prompt
  const handleImagePromptSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/shot-image/update-image-prompt/${dialogueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          image_prompt: editedImagePrompt
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update image prompt');
      }

      // Update the state with the new prompt
      setImagePrompt(editedImagePrompt);
      setIsEditingImagePrompt(false);
    } catch (err) {
      console.error('Error updating image prompt:', err);
      onError('Failed to update image prompt. Please try again.');
    }
  };

  // Function to start polling for image status with fixed logic
  const startPolling = () => {
    setIsPolling(true);
    setStatusMessage('Waiting for image to generate...');
    
    // Clear any existing polling
    if (pollIntervalRef.current) clearInterval(pollIntervalRef.current);
    if (pollTimeoutRef.current) clearTimeout(pollTimeoutRef.current);
    
    // Start polling interval
    pollIntervalRef.current = setInterval(async () => {
      try {
        // Check the status via the new status endpoint
        const statusResponse = await fetch(
          `${API_BASE_URL}/shot-image/check-status/${dialogueId}?user_id=${userId}`
        );
        
        if (!statusResponse.ok) {
          console.error('Error checking image status:', statusResponse.statusText);
          return; // Continue polling despite errors
        }
        
        const statusData = await statusResponse.json();
        console.log('Image status update:', statusData);
        
        // Update status message based on generation status
        if (statusData.status === 'generating') {
          setStatusMessage('Image is being generated...');
        } else if (statusData.status === 'failed') {
          clearInterval(pollIntervalRef.current);
          clearTimeout(pollTimeoutRef.current);
          setIsPolling(false);
          onError(`Image generation failed: ${statusData.error || 'Unknown error'}`);
          setStatusMessage(null);
        }
        
        // Check storage status if available
        if (statusData.storage_status) {
          setImageStorageStatus(statusData.storage_status);
          
          // Show storage status message if applicable
          if (statusData.storage_status === 'downloading') {
            setStatusMessage('Image is being saved to storage...');
          } else if (statusData.storage_status === 'uploading') {
            setStatusMessage('Image is being uploaded to storage...');
          }
        }
        
        // If the image URL is available, update the UI
        if (statusData.image_url) {
          // Update URLs based on what's available
          // Prioritize stored image URL if available
          if (statusData.is_stored) {
            setStoredImageUrl(statusData.image_url);
            setImageUrl(statusData.image_url);
          } else {
            setImageUrl(statusData.image_url);
          }
          
          if (statusData.image_prompt && !imagePrompt) {
            setImagePrompt(statusData.image_prompt);
            setEditedImagePrompt(statusData.image_prompt);
          }
          
          // Only stop polling when both generation AND storage are complete
          if (statusData.status === 'completed' && 
              (statusData.storage_status === 'completed' || 
               statusData.storage_status === 'failed')) {
            clearInterval(pollIntervalRef.current);
            clearTimeout(pollTimeoutRef.current);
            setIsPolling(false);
            setStatusMessage(null);
          }
        }
      } catch (err) {
        console.error('Error polling for image status:', err);
        // Continue polling despite errors
      }
    }, 3000); // Poll every 3 seconds
    
    // Set a timeout to stop polling after 5 minutes
    pollTimeoutRef.current = setTimeout(() => {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
      onError('Image generation took too long. Please try again later.');
      setStatusMessage(null);
    }, 300000); // 5 minutes
  };

  // Function for generating shot image with async handling
  const handleGenerateShotImage = async () => {
    try {
      setIsGeneratingImage(true);
      onError(null); // Clear any previous errors
      setStatusMessage('Initiating image generation...');
      
      // Clear current image to ensure state update when new one arrives
      setImageUrl(null);
      setStoredImageUrl(null);
      setImageStorageStatus(null);
      
      // Create an AbortController to handle the timeout
      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30-second timeout
      
      try {
        const response = await fetch(`${API_BASE_URL}/shot-image/generate-shot-image/${dialogueId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId
          }),
          signal, // Add the abort signal
        });
        
        // Clear the timeout since the request completed
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Failed to initiate image generation');
        }
        
        const data = await response.json();
        
        // Set the image prompt if returned
        if (data.image_prompt) {
          setImagePrompt(data.image_prompt);
          setEditedImagePrompt(data.image_prompt);
        }
        
        // Start polling for the image generation result
        startPolling();
      } catch (err) {
        // Special handling for timeout/abort errors
        if (err.name === 'AbortError') {
          console.log('Request timed out but may still be processing on the server');
          setStatusMessage('Image generation may take longer. Polling for status...');
          // Start polling anyway, assuming the backend is still processing
          startPolling();
        } else {
          // Handle other errors
          onError(`Failed to generate shot image: ${err.message}`);
          setStatusMessage(null);
        }
      }
    } catch (err) {
      onError(`Unexpected error: ${err.message}`);
      setStatusMessage(null);
    } finally {
      setIsGeneratingImage(false);
    }
  };

  return (
    <>
      {/* Image generation status message display */}
      {statusMessage && (
        <div style={{
          padding: '10px',
          marginBottom: '15px',
          backgroundColor: 'rgba(0, 123, 255, 0.1)',
          borderRadius: '4px',
          color: '#007BFF',
          textAlign: 'center'
        }}>
          {statusMessage}
        </div>
      )}

      {/* Editable Image Prompt field */}
      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {permissionLevel !== 'viewer' && (
            <button
              onClick={() => {
                setIsEditingImagePrompt(!isEditingImagePrompt);
                setEditedImagePrompt(imagePrompt || '');
              }}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Image Prompt:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingImagePrompt ? (
            <>
              <textarea
                value={editedImagePrompt}
                onChange={handleImagePromptChange}
                style={{...styles.textarea, textAlign: 'left'}}
                placeholder="Enter image prompt"
                rows={4}
              />
              <button onClick={handleImagePromptSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <div style={{
              padding: '15px',
              backgroundColor: 'rgba(255,255,255,0.05)',
              borderRadius: '8px',
              textAlign: 'left'
            }}>
              {imagePrompt || 'No image prompt set. Generate one by clicking the button below, or create your own by clicking the edit button.'}
            </div>
          )}
        </div>
      </div>

      {/* Image generation button */}
      <div style={styles.formGroup}>
        <button
          onClick={handleGenerateShotImage}
          style={{
            ...styles.button,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            padding: '10px 20px',
            backgroundColor: (isGeneratingImage || isPolling) ? '#555' : '#007BFF',
            opacity: (isGeneratingImage || isPolling) ? 0.7 : 1,
            cursor: (isGeneratingImage || isPolling) ? 'not-allowed' : 'pointer'
          }}
          disabled={isGeneratingImage || isPolling}
        >
          <Image size={16} />
          {isGeneratingImage 
            ? 'Initiating...' 
            : isPolling 
              ? 'Generating...' 
              : 'Generate Shot Image'}
        </button>
      </div>
        
      {/* Display generated image if available with better loading and error handling */}
      {imageUrl && (
        <div style={styles.formGroup}>
          <label style={styles.label}>
            Generated Image:
            {imageStorageStatus && imageStorageStatus !== 'completed' && (
              <span style={{
                fontSize: '12px',
                marginLeft: '8px',
                color: '#FFA500',
                backgroundColor: 'rgba(255, 165, 0, 0.1)',
                borderRadius: '4px',
                padding: '2px 6px',
              }}>
                {imageStorageStatus === 'downloading' ? 'Downloading to Storage...' : 
                 imageStorageStatus === 'uploading' ? 'Uploading to Storage...' : imageStorageStatus}
              </span>
            )}
            {storedImageUrl && (
              <span style={{
                fontSize: '12px',
                marginLeft: '8px',
                color: '#90EE90',
                backgroundColor: 'rgba(0, 100, 0, 0.1)',
                borderRadius: '4px',
                padding: '2px 6px',
              }}>
                Stored in Cloud
              </span>
            )}
          </label>
          <div style={{
            padding: '15px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <img 
              key={`generated-image-${imageUrl}`} // Add a key based on URL to force re-render
              src={imageUrl}
              alt="Generated shot"
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
                borderRadius: '4px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
              }}
              onError={(e) => {
                console.error('Image failed to load, retrying...');
                // If load fails, add a timestamp to retry loading
                setTimeout(() => {
                  e.target.src = `${imageUrl}?t=${Date.now()}`;
                }, 1000);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShotImage;