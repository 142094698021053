// src/pages/ScriptMediaScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import ScriptCharactersTable from '../components/ScriptCharactersTable';
import CharacterValidationTable from '../components/CharacterValidationTable';
import ShotMediaTable from '../components/ShotMediaTable';
import ScriptLocationsTable from '../components/ScriptLocationsTable';
import GenerateScriptAudio from '../components/GenerateScriptAudio';
import GenerateShotTypes from '../components/GenerateShotTypes';
import GenerateShotImages from '../components/GenerateShotImages';

const ScriptMediaScreen = () => {
    const [story, setStory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [parsedLines, setParsedLines] = useState([]);
    const [isParsing, setIsParsing] = useState(false);
    const [parsingStatus, setParsingStatus] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [validationStatus, setValidationStatus] = useState(null);
    const [locationRefreshTrigger, setLocationRefreshTrigger] = useState(0);
    const [isGeneratingCharacters, setIsGeneratingCharacters] = useState(false);
    const [generationMessage, setGenerationMessage] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    // State for character generation polling
    const [isPollingGeneration, setIsPollingGeneration] = useState(false);
    const [generationStatus, setGenerationStatus] = useState(null);
    const [pollingAttempts, setPollingAttempts] = useState(0);
    const [autoRefresh, setAutoRefresh] = useState(false);
    
    // New state for audio functionality
    const [audioStatus, setAudioStatus] = useState({});
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    
    const { user } = useUser();
    const { storyId } = useParams();
    const navigate = useNavigate();

    // Auto-refresh data if flag is set
    useEffect(() => {
        if (autoRefresh) {
            const timer = setTimeout(() => {
                fetchParsedLines();
                setAutoRefresh(false);
            }, 5000);
            
            return () => clearTimeout(timer);
        }
    }, [autoRefresh]);

    useEffect(() => {
        if (user.isAuthenticated) {
            fetchStoryDetails();
            fetchParsedLines();
        } else {
            navigate('/login');
        }
    }, [storyId, user.isAuthenticated, navigate]);

    // New effect to check existing audio after parsed lines are loaded
    useEffect(() => {
        if (user.isAuthenticated && parsedLines.length > 0) {
            checkExistingAudio();
        }
    }, [storyId, user.isAuthenticated, parsedLines.length]);

    const fetchStoryDetails = async () => {
        if (!user.user_id || !storyId) return;

        try {
            const response = await fetch(`${API_BASE_URL}/story/${storyId}/details?user_id=${user.user_id}`);
          
            if (!response.ok) {
                throw new Error('Failed to fetch story details');
            }

            const data = await response.json();
            setStory(data.story);
            setProjectId(data.projectId);
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to fetch story details');
        }
    };

    const fetchParsedLines = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/script-parsing/get-parsed-lines/${storyId}?user_id=${user.user_id}`);
          
            if (!response.ok) {
                throw new Error('Failed to fetch parsed lines');
            }

            const data = await response.json();
            setParsedLines(data.parsed_lines);

            // After getting parsed lines, check voice validation
            await checkVoiceValidation();
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to fetch parsed lines');
        } finally {
            setIsLoading(false);
        }
    };

    // New function to check existing audio (moved from ShotMediaTable)
    const checkExistingAudio = async () => {
        try {
            console.log('Checking existing audio for story:', storyId);
            const response = await fetch(
                `${API_BASE_URL}/script-audio/get-existing-audio/${storyId}?user_id=${user.user_id}`
            );
      
            if (!response.ok) {
                throw new Error('Failed to check existing audio');
            }
      
            const data = await response.json();
            if (Object.keys(data.existing_audio).length > 0) {
                console.log('Found existing audio:', data.existing_audio);
                
                // Create a fixed version of the status to handle SILENCE files properly
                const fixedAudioStatus = {...data.existing_audio};
                
                // Look for any entries with status "generating" but with a valid URL
                // This happens with quick SILENCE generations where the status update is missed
                Object.entries(fixedAudioStatus).forEach(([lineNum, status]) => {
                    if (status.status === 'generating' && status.url) {
                        console.log(`Fixing status for line ${lineNum} - has URL but status is still 'generating'`);
                        fixedAudioStatus[lineNum] = {
                            ...status,
                            progress: 100,
                            status: 'completed'
                        };
                    }
                });
                
                // Use the fixed status instead of the original
                setAudioStatus(fixedAudioStatus);
            }
            setIsAudioInitialized(true);
        } catch (err) {
            console.error('Error checking existing audio:', err);
            setError('Failed to check existing audio');
            setIsAudioInitialized(true);
        }
    };

    // New function to handle audio updates from GenerateScriptAudio
    const handleAudioUpdate = (data) => {
        if (data.complete) {
            // Refresh audio status after completion
            checkExistingAudio();
            return;
        }

        // Update status for the specific line
        setAudioStatus(current => {
            console.log('Updating status for line', data.line_number, 'Progress:', data.progress, 'Status:', data.status);
            const newStatus = { ...current };
            const lineStatus = newStatus[data.line_number] || {};
            
            newStatus[data.line_number] = {
                ...lineStatus,
                progress: data.progress,
                url: data.url || lineStatus.url,
                status: data.status || lineStatus.status,
                message: data.message || lineStatus.message
            };
            
            // Force a re-render by creating a new object
            return { ...newStatus };
        });
    };

    const checkVoiceValidation = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/script-character-generation/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user.user_id,
                    story_id: storyId,
                }),
            });
      
            if (!response.ok) {
                throw new Error('Failed to validate voices');
            }
      
            const data = await response.json();
            setValidationStatus(data);
        } catch (err) {
            console.error('Error checking voice validation:', err);
        }
    };

    // Improved polling function with timeout handling
    const pollGenerationStatus = async () => {
        try {
            setIsPollingGeneration(true);
            
            // Create abort controller for timeout handling
            const abortController = new AbortController();
            const timeoutId = setTimeout(() => abortController.abort(), 25000); // 25 second timeout
            
            try {
                const response = await fetch(
                    `${API_BASE_URL}/script-character-generation/generation-status/${storyId}?user_id=${user.user_id}`,
                    { signal: abortController.signal }
                );
                
                clearTimeout(timeoutId); // Clear timeout if fetch succeeded
                
                if (!response.ok) {
                    throw new Error('Failed to check generation status');
                }
                
                const data = await response.json();
                setGenerationStatus(data);
                
                if (data.allComplete) {
                    // All characters are complete, stop polling and refresh data
                    setIsPollingGeneration(false);
                    setStatusMessage('Character generation completed successfully!');
                    setPollingAttempts(0);
                    await fetchParsedLines();
                } else {
                    // Continue polling if not all complete
                    const pendingCount = data.pendingCount || 0;
                    setStatusMessage(`Still generating ${pendingCount} character(s)... This may take a minute.`);
                    setPollingAttempts(prev => prev + 1);
                    
                    // If we've been polling for a while, suggest a refresh
                    if (pollingAttempts > 15) { // After about 30 seconds of polling
                        setStatusMessage('Generation is taking longer than expected. You can continue waiting or refresh the page in a minute to see results.');
                        // Automatically schedule a data refresh
                        setAutoRefresh(true);
                        setIsPollingGeneration(false);
                    } else {
                        setTimeout(pollGenerationStatus, 2000); // Poll every 2 seconds
                    }
                }
            } catch (err) {
                clearTimeout(timeoutId);
                
                if (err.name === 'AbortError') {
                    // Request timed out, assume progress is still happening and schedule a refresh
                    console.log('Status check timed out, continuing process...');
                    setStatusMessage('Generation is in progress but taking some time. Please wait or refresh the page in a minute to see results.');
                    setAutoRefresh(true);
                    setIsPollingGeneration(false);
                } else {
                    console.error('Error polling generation status:', err);
                    setIsPollingGeneration(false);
                    setStatusMessage('Error checking character generation status. Please refresh the page in a minute to see results.');
                }
            }
        } catch (err) {
            console.error('Error in poll function:', err);
            setIsPollingGeneration(false);
            setStatusMessage('Error checking character generation status. Please refresh the page in a minute to see results.');
        }
    };

    const handleParse = async () => {
        setIsParsing(true);
        setError(null);
        setParsingStatus({ status: 'starting' });

        try {
            const response = await fetch(`${API_BASE_URL}/script-parsing/parse-script`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    user_id: user.user_id,
                    story_id: storyId
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to start script parsing');
            }

            const data = await response.json();
            setParsingStatus({ status: data.status, message: data.message });
          
            // Start polling for status
            checkParsingStatus();
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to start parsing script');
            setIsParsing(false);
            setParsingStatus({ status: 'failed', error: err.message });
        }
    };

    const checkParsingStatus = async () => {
        try {
            const response = await fetch(
                `${API_BASE_URL}/script-parsing/parsing-status/${storyId}?user_id=${user.user_id}`
            );
          
            if (!response.ok) {
                throw new Error('Failed to check parsing status');
            }
          
            const data = await response.json();
            setParsingStatus(data);
          
            if (data.status === 'completed') {
                // Parsing completed successfully
                await fetchParsedLines();
                setLocationRefreshTrigger(prev => prev + 1); // Trigger location table refresh
                setIsParsing(false);
            } else if (data.status === 'failed') {
                // Parsing failed
                setError(`Script parsing failed: ${data.error || 'Unknown error'}`);
                setIsParsing(false);
            } else if (data.status === 'processing') {
                // Still processing, continue polling
                setTimeout(checkParsingStatus, 2000); // Poll every 2 seconds
            } else {
                // Unknown status
                setError('Unknown parsing status received');
                setIsParsing(false);
            }
        } catch (err) {
            console.error('Error checking status:', err);
            setError('Failed to check parsing status');
            setIsParsing(false);
        }
    };

    const handleValidationResult = (result) => {
        setValidationStatus(result);
    };

    // Updated handler for generating missing characters with improved error handling
    const handleGenerateMissingCharacters = async () => {
        setIsGeneratingCharacters(true);
        setGenerationMessage(null);
        setStatusMessage(null);
        setError(null);
        setPollingAttempts(0);
        
        try {
            // Create an abort controller for timeout handling
            const abortController = new AbortController();
            const timeoutId = setTimeout(() => abortController.abort(), 28000); // Just under Heroku's 30s limit
            
            try {
                const response = await fetch(`${API_BASE_URL}/script-character-generation/generate-missing-characters`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: user.user_id,
                        story_id: storyId,
                    }),
                    signal: abortController.signal
                });
                
                clearTimeout(timeoutId);
                
                if (!response.ok) {
                    throw new Error('Failed to generate missing characters');
                }
                
                const data = await response.json();
                
                // Update generation message to include voice assignment info
                setGenerationMessage(data.message);
                
                // Start polling for generation status instead of immediately fetching
                if (data.image_generation_status === 'in_progress') {
                    // Include voice assignment info in status message
                    const voiceInfo = data.voice_assigned_count ? 
                        `${data.voice_assigned_count} characters have been assigned voices. ` : '';
                        
                    setStatusMessage(`Character generation initiated. ${voiceInfo}Waiting for character generation to complete...`);
                    
                    // Begin polling generation status
                    pollGenerationStatus();
                } else {
                    // No characters were generated, refresh data
                    await fetchParsedLines();
                    setIsGeneratingCharacters(false);
                }
            } catch (err) {
                clearTimeout(timeoutId);
                
                if (err.name === 'AbortError') {
                    // The request timed out, but characters might still be generating
                    console.log('Generation request timed out, but may still be in progress');
                    setStatusMessage('Character generation initiated, but taking longer than expected. Results will appear shortly. You can refresh the page in a minute to see results.');
                    setAutoRefresh(true);
                    setIsGeneratingCharacters(false);
                } else {
                    console.error('Error:', err);
                    setError('Failed to generate missing characters: ' + err.message);
                    setIsGeneratingCharacters(false);
                }
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to generate missing characters: ' + err.message);
            setIsGeneratingCharacters(false);
        }
    };

    if (isLoading) return <div style={styles.container}>Loading...</div>;
    if (error) return <div style={styles.container}>{error}</div>;
    if (!story) return <div style={styles.container}>Story not found.</div>;

    return (
        <div style={styles.container}>
            <div style={styles.linkContainer}>
                <Link to={`/story/${storyId}`} style={styles.link}>
                    &lt;&lt; Back to Story
                </Link>
            </div>
          
            <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
                Create Media for {story.title}
            </h1>

            <div style={styles.formGroup}>
                <button
                    onClick={handleParse}
                    style={{...styles.button, marginBottom: '20px'}}
                    disabled={isParsing}
                >
                    {isParsing ? 'Parsing Script...' : 'Parse Script'}
                </button>

                {isParsing && (
                    <div style={{
                        textAlign: 'center',
                        padding: '10px',
                        marginBottom: '20px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        borderRadius: '4px'
                    }}>
                        <p>Script parsing in progress...</p>
                        {parsingStatus && (
                            <p style={{ fontSize: '0.9em', color: '#ccc' }}>
                                {parsingStatus.status === 'processing' ? 'Processing your script with AI...' : parsingStatus.message}
                            </p>
                        )}
                    </div>
                )}

                {parsedLines.length > 0 && (
                    <>
                        <div style={{overflowX: 'auto', width: '100%', marginBottom: '40px'}}>
                            <table style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                marginTop: '20px',
                                backgroundColor: 'rgba(255,255,255,0.05)',
                            }}>
                                <thead>
                                    <tr>
                                        <th style={{
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderBottom: '2px solid #444',
                                            backgroundColor: '#333',
                                            whiteSpace: 'nowrap',
                                        }}>Line</th>
                                        <th style={{
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderBottom: '2px solid #444',
                                            backgroundColor: '#333',
                                        }}>Scene Location</th>
                                        <th style={{
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderBottom: '2px solid #444',
                                            backgroundColor: '#333',
                                        }}>Character</th>
                                        <th style={{
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderBottom: '2px solid #444',
                                            backgroundColor: '#333',
                                        }}>Stage Directions</th>
                                        <th style={{
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderBottom: '2px solid #444',
                                            backgroundColor: '#333',
                                        }}>Dialogue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parsedLines.map((line) => (
                                        <tr key={line.line_number}>
                                            <td style={{
                                                padding: '12px',
                                                borderBottom: '1px solid #444',
                                                whiteSpace: 'nowrap',
                                            }}>{line.line_number}</td>
                                            <td style={{
                                                padding: '12px',
                                                borderBottom: '1px solid #444',
                                                color: line.scene_location ? '#fff' : '#555',
                                                fontStyle: line.scene_location ? 'normal' : 'italic',
                                            }}>{line.scene_location || 'No location'}</td>
                                            <td style={{
                                                padding: '12px',
                                                borderBottom: '1px solid #444',
                                            }}>{line.character_name}</td>
                                            <td style={{
                                                padding: '12px',
                                                borderBottom: '1px solid #444',
                                                fontStyle: 'italic',
                                                color: '#999',
                                            }}>{line.stage_directions}</td>
                                            <td style={{
                                                padding: '12px',
                                                borderBottom: '1px solid #444',
                                            }}>{line.dialogue_text}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Scene Locations Table */}
                        <ScriptLocationsTable 
                            storyId={storyId} 
                            refreshTrigger={locationRefreshTrigger} 
                        />

                        {/* Only render character table if not polling for generation */}
                        {!isPollingGeneration && (
                            <ScriptCharactersTable 
                                storyId={storyId}
                                parsedLines={parsedLines}
                                projectId={projectId}
                            />
                        )}

                        {/* Generation loading state */}
                        {isPollingGeneration && (
                            <div style={{
                                margin: '40px 0',
                                padding: '20px',
                                textAlign: 'center',
                                backgroundColor: 'rgba(33,150,243,0.1)',
                                borderRadius: '4px'
                            }}>
                                <h2 style={{...styles.formTitle, fontSize: '1.5rem', marginBottom: '20px'}}>
                                    Generating Characters...
                                </h2>
                                <p>Please wait while we generate characters, assign voices, and create character images.</p>
                                {generationStatus && (
                                    <p style={{ fontSize: '0.9em', color: '#ccc' }}>
                                        {generationStatus.pendingCount} characters remaining...
                                    </p>
                                )}
                            </div>
                        )}

                        {/* Generate Missing Characters Button */}
                        <div style={{marginTop: '20px', textAlign: 'center'}}>
                            <button
                                onClick={handleGenerateMissingCharacters}
                                style={{...styles.button, marginBottom: '10px'}}
                                disabled={isGeneratingCharacters || isPollingGeneration || autoRefresh}
                            >
                                {isGeneratingCharacters || isPollingGeneration ? 'Generating Characters...' : 
                                 autoRefresh ? 'Refreshing Soon...' : 'Generate Missing Characters'}
                            </button>
                          
                            {generationMessage && (
                                <div style={{
                                    color: '#4CAF50', 
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    padding: '10px',
                                    backgroundColor: 'rgba(76,175,80,0.1)',
                                    borderRadius: '4px'
                                }}>
                                    {generationMessage}
                                </div>
                            )}
                          
                            {statusMessage && (
                                <div style={{
                                    color: '#2196F3', // Blue color for information
                                    marginTop: '10px',
                                    textAlign: 'center',
                                    padding: '10px',
                                    backgroundColor: 'rgba(33,150,243,0.1)',
                                    borderRadius: '4px',
                                    marginBottom: '20px'
                                }}>
                                    {statusMessage}
                                </div>
                            )}
                            
                            {autoRefresh && (
                                <div style={{
                                    color: '#FF9800', // Orange color for waiting
                                    marginTop: '10px',
                                    textAlign: 'center',
                                    padding: '10px',
                                    backgroundColor: 'rgba(255,152,0,0.1)',
                                    borderRadius: '4px',
                                    marginBottom: '20px'
                                }}>
                                    <p>Data will refresh automatically in a few seconds...</p>
                                </div>
                            )}
                        </div>

                        <CharacterValidationTable
                            storyId={storyId}
                            parsedLines={parsedLines}
                            projectId={projectId}
                            onValidationResult={handleValidationResult}
                        />

                        {validationStatus?.allValid && (
                            <>
                                {/* Add spacing between validation table and audio controls */}
                                <div style={{ marginTop: '40px' }}></div>
                                
                                {/* Existing GenerateScriptAudio component */}
                                <GenerateScriptAudio
                                    storyId={storyId}
                                    userId={user.user_id}
                                    onUpdate={handleAudioUpdate}
                                />
                                
                                {/* Add spacing between audio controls and shot type controls */}
                                <div style={{ marginTop: '20px' }}></div>
                                
                                {/* Insert the GenerateShotTypes component */}
                                <GenerateShotTypes
                                    storyId={storyId}
                                    userId={user.user_id}
                                    onGenerationComplete={fetchParsedLines} // Re-fetch data after generation
                                />

                                {/* Add spacing between shot type controls and image prompt controls */}
                                <div style={{ marginTop: '20px' }}></div>

                                {/* Insert the GenerateShotImages component */}
                                <GenerateShotImages
                                    storyId={storyId}
                                    userId={user.user_id}
                                    onGenerationComplete={fetchParsedLines} // Re-fetch data after generation
                                />
                                
                                {/* Pass audio status to ShotMediaTable */}
                                <ShotMediaTable
                                    storyId={storyId}
                                    parsedLines={parsedLines}
                                    audioStatus={audioStatus}
                                    isAudioInitialized={isAudioInitialized}
                                />
                            </>
                        )}
                    </>
                )}

                {!parsedLines.length && !isParsing && (
                    <p style={{textAlign: 'center', color: '#666'}}>
                        No parsed lines available. Click "Parse Script" to begin.
                    </p>
                )}
            </div>
        </div>
    );
};

export default ScriptMediaScreen;