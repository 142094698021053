// src/components/ShotAudio.js

import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { styles } from '../styles';

const ShotAudio = ({ 
  storyId, 
  lineNumber, 
  userId, 
  initialAudioUrl,
  onAudioUrlChange,
  onError 
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localAudioUrl, setLocalAudioUrl] = useState(initialAudioUrl);

  // Effect to fetch audio if not already available
  useEffect(() => {
    if (!initialAudioUrl && storyId && lineNumber) {
      fetchAudioForShot();
    } else if (initialAudioUrl) {
      setLocalAudioUrl(initialAudioUrl);
    }
  }, [initialAudioUrl, storyId, lineNumber]);

  // Handle local state changes and propagate to parent
  useEffect(() => {
    if (localAudioUrl !== initialAudioUrl) {
      onAudioUrlChange(localAudioUrl);
    }
  }, [localAudioUrl, initialAudioUrl]);

  const fetchAudioForShot = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/script-audio/get-existing-audio/${storyId}?user_id=${userId}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch audio');
      }
      
      const data = await response.json();
      
      if (data.existing_audio && data.existing_audio[lineNumber]) {
        setLocalAudioUrl(data.existing_audio[lineNumber].url);
      }
    } catch (err) {
      console.error('Error fetching audio:', err);
      onError('Failed to fetch audio');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.formGroup}>
      <label style={styles.label}>Audio:</label>
      {isLoading ? (
        <div>Loading audio...</div>
      ) : localAudioUrl ? (
        <audio controls src={localAudioUrl} style={{width: '100%'}}>
          Your browser does not support the audio element.
        </audio>
      ) : (
        <div style={{padding: '15px', backgroundColor: 'rgba(255,255,255,0.05)', borderRadius: '8px'}}>
          No audio available for this shot.
        </div>
      )}
    </div>
  );
};

export default ShotAudio;