// src/components/ShotVideo.js

import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../config';
import { Video, Pencil } from 'lucide-react';
import { styles } from '../styles';

const ShotVideo = ({ 
  dialogueId, 
  userId, 
  imageUrl,
  audioUrl,
  characterName,
  initialVideoUrl,
  initialStoredVideoUrl,
  initialShotType,
  permissionLevel,
  onVideoUrlChange,
  onStoredVideoUrlChange,
  onShotTypeChange, // New prop for updating shot type
  onError
}) => {
  // State variables for video type
  const [shotType, setShotType] = useState(initialShotType || 'talking');
  
  // State variables for video generation
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
  const [isPollingVideo, setIsPollingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(initialVideoUrl);
  const [storedVideoUrl, setStoredVideoUrl] = useState(initialStoredVideoUrl);
  const [videoStorageStatus, setVideoStorageStatus] = useState(null);
  const [videoStatusMessage, setVideoStatusMessage] = useState(null);
  
  // New state variable to control when to show the video
  const [showVideo, setShowVideo] = useState(!!initialVideoUrl);
  
  // State variables for action video prompt
  const [actionVideoPrompt, setActionVideoPrompt] = useState(null);
  const [isEditingActionVideoPrompt, setIsEditingActionVideoPrompt] = useState(false);
  const [editedActionVideoPrompt, setEditedActionVideoPrompt] = useState('');
  
  // Refs for video polling intervals and timeouts
  const videoPollIntervalRef = useRef(null);
  const videoPollTimeoutRef = useRef(null);

  // Effect to fetch action video prompt when shot type changes
  useEffect(() => {
    if (shotType === 'action' && dialogueId && userId) {
      fetchActionVideoPrompt();
    }
  }, [shotType, dialogueId, userId]);

  // Effect to update parent component's state when local state changes
  useEffect(() => {
    if (videoUrl !== initialVideoUrl) {
      onVideoUrlChange && onVideoUrlChange(videoUrl);
    }
    if (storedVideoUrl !== initialStoredVideoUrl) {
      onStoredVideoUrlChange && onStoredVideoUrlChange(storedVideoUrl);
    }
  }, [videoUrl, storedVideoUrl, initialVideoUrl, initialStoredVideoUrl]);

  // Clean up any intervals or timeouts when component unmounts
  useEffect(() => {
    return () => {
      if (videoPollIntervalRef.current) clearInterval(videoPollIntervalRef.current);
      if (videoPollTimeoutRef.current) clearTimeout(videoPollTimeoutRef.current);
    };
  }, []);

  // Function to fetch action video prompt
  const fetchActionVideoPrompt = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/shot-video-prompted/get-prompt/${dialogueId}?user_id=${userId}`
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching action video prompt:', errorData);
        // Don't set error - just log it since this isn't critical
        return;
      }
      
      const data = await response.json();
      if (data.prompt) {
        setActionVideoPrompt(data.prompt);
        setEditedActionVideoPrompt(data.prompt);
      } else {
        // Set a default prompt if none exists
        const defaultPrompt = `Create an action video for "${characterName}" showing the scene described in the dialogue`;
        setActionVideoPrompt(defaultPrompt);
        setEditedActionVideoPrompt(defaultPrompt);
      }
    } catch (err) {
      console.error('Error fetching action video prompt:', err);
      // Don't set error - just log it since this isn't critical
    }
  };

  // Function to handle action video prompt changes
  const handleActionVideoPromptChange = (e) => {
    setEditedActionVideoPrompt(e.target.value);
  };

  // Function to save the action video prompt
  const handleActionVideoPromptSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/shot-video-prompted/update-prompt/${dialogueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          prompt: editedActionVideoPrompt
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update action video prompt');
      }

      // Update the state with the new prompt
      setActionVideoPrompt(editedActionVideoPrompt);
      setIsEditingActionVideoPrompt(false);
    } catch (err) {
      console.error('Error updating action video prompt:', err);
      onError('Failed to update action video prompt. Please try again.');
    }
  };

  // Function to start polling for video status - UPDATED to handle audio processing
  const startVideoPolling = () => {
    setIsPollingVideo(true);
    setVideoStatusMessage('Waiting for video to generate...');
    
    // Clear any existing polling
    if (videoPollIntervalRef.current) clearInterval(videoPollIntervalRef.current);
    if (videoPollTimeoutRef.current) clearTimeout(videoPollTimeoutRef.current);
    
    // Hide any existing video while generating a new one
    setShowVideo(false);
    
    // Determine which endpoint to use based on shotType
    const endpoint = shotType === 'talking' 
      ? `${API_BASE_URL}/shot-video/check-video-status/${dialogueId}`
      : `${API_BASE_URL}/shot-video-prompted/check-action-video-status/${dialogueId}`;
    
    // Start polling interval
    videoPollIntervalRef.current = setInterval(async () => {
      try {
        // Check the status via the appropriate endpoint
        const statusResponse = await fetch(
          `${endpoint}?user_id=${userId}`
        );
        
        if (!statusResponse.ok) {
          console.error('Error checking video status:', statusResponse.statusText);
          return; // Continue polling despite errors
        }
        
        const statusData = await statusResponse.json();
        console.log('Video status update:', statusData);
        
        // Update status message based on generation status
        if (statusData.status === 'generating') {
          setVideoStatusMessage(`${shotType === 'talking' ? 'Talking' : 'Action'} video is being generated...`);
        } else if (statusData.status === 'audio_processing') {
          // NEW: Handle audio processing status
          setVideoStatusMessage('Adding audio to video... this may take a minute');
        } else if (statusData.status === 'failed') {
          clearInterval(videoPollIntervalRef.current);
          clearTimeout(videoPollTimeoutRef.current);
          setIsPollingVideo(false);
          onError(`Video generation failed: ${statusData.error || 'Unknown error'}`);
          setVideoStatusMessage(null);
        }
        
        // Check the storage status if available
        if (statusData.storage_status) {
          setVideoStorageStatus(statusData.storage_status);
          
          // Show storage status message if applicable
          if (statusData.storage_status === 'downloading') {
            setVideoStatusMessage('Video is being saved to storage...');
          } else if (statusData.storage_status === 'uploading') {
            setVideoStatusMessage('Video is being uploaded to storage...');
          } else if (statusData.storage_status === 'overlaying_audio') {
            setVideoStatusMessage('Adding audio to video... this may take a minute');
          } else if (statusData.storage_status === 'audio_overlay_completed') {
            setVideoStatusMessage('Audio added successfully, finalizing video...');
          } else if (statusData.storage_status === 'audio_overlay_failed') {
            setVideoStatusMessage('Audio processing failed, using original video...');
          }
        }
        
        // If the video URL is available, check if we're ready to show it
        if (statusData.video_url) {
          // Only update and show the video if we're truly completed
          if (statusData.status === 'completed') {
            // First check if we have a stored video URL available (from Firebase Storage)
            if (statusData.is_stored) {
              setStoredVideoUrl(statusData.video_url);
              setVideoUrl(statusData.video_url);
            } else {
              // Otherwise use the direct URL
              setVideoUrl(statusData.video_url);
            }
            
            // Now we can show the video since it has audio
            setShowVideo(true);
            
            // Stop polling when both generation AND storage are complete
            if ((!statusData.storage_status || 
                 statusData.storage_status === 'completed' || 
                 statusData.storage_status === 'audio_overlay_completed')) {
              clearInterval(videoPollIntervalRef.current);
              clearTimeout(videoPollTimeoutRef.current);
              setIsPollingVideo(false);
              setVideoStatusMessage(null);
            }
          }
        }
      } catch (err) {
        console.error('Error polling for video status:', err);
        // Continue polling despite errors
      }
    }, 5000); // Poll every 5 seconds
    
    // Set a timeout to stop polling after 10 minutes
    videoPollTimeoutRef.current = setTimeout(() => {
      clearInterval(videoPollIntervalRef.current);
      setIsPollingVideo(false);
      onError('Video generation took too long. Please try again later.');
      setVideoStatusMessage(null);
    }, 600000); // 10 minutes
  };

  // Function for generating talking video
  const handleGenerateShotVideo = async () => {
    // First, validate that we have an image
    if (!imageUrl) {
      onError('An image is required to generate a video. Please generate an image first.');
      return;
    }
    
    // For talking videos, we need audio unless it's a non-speaking part
    if (shotType === 'talking' && !audioUrl && characterName !== "Non-speaking part") {
      onError('Audio is required to generate a talking video. No audio found for this shot.');
      return;
    }
    
    try {
      setIsGeneratingVideo(true);
      onError(null);
      setVideoStatusMessage('Initiating video generation...');
      
      // Clear current video to ensure state update when new one arrives
      setVideoUrl(null);
      setStoredVideoUrl(null);
      setVideoStorageStatus(null);
      setShowVideo(false); // Hide any previous video
      
      // Create an AbortController to handle the timeout
      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30-second timeout
      
      try {
        const response = await fetch(`${API_BASE_URL}/shot-video/generate-talking-video/${dialogueId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId
          }),
          signal, // Add the abort signal
        });
        
        // Clear the timeout since the request completed
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Failed to initiate video generation');
        }
        
        const data = await response.json();
        
        // Start polling for the video generation result
        startVideoPolling();
      } catch (err) {
        // Special handling for timeout/abort errors
        if (err.name === 'AbortError') {
          console.log('Request timed out but may still be processing on the server');
          setVideoStatusMessage('Video generation may take longer. Polling for status...');
          // Start polling anyway, assuming the backend is still processing
          startVideoPolling();
        } else {
          // Handle other errors
          onError(`Failed to generate video: ${err.message}`);
          setVideoStatusMessage(null);
        }
      }
    } catch (err) {
      onError(`Unexpected error: ${err.message}`);
      setVideoStatusMessage(null);
    } finally {
      setIsGeneratingVideo(false);
    }
  };

  // Function for generating action video
  const handleGenerateActionVideo = async () => {
    if (!imageUrl) {
      onError('An image is required to generate a video. Please generate an image first.');
      return;
    }
    
    if (!actionVideoPrompt) {
      onError('A video prompt is required. Please add a prompt first.');
      return;
    }
    
    try {
      setIsGeneratingVideo(true);
      onError(null);
      setVideoStatusMessage('Initiating action video generation...');
      
      // Clear current video to ensure state update when new one arrives
      setVideoUrl(null);
      setStoredVideoUrl(null);
      setVideoStorageStatus(null);
      setShowVideo(false); // Hide any previous video
      
      // Create an AbortController to handle the timeout
      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30-second timeout
      
      try {
        const response = await fetch(`${API_BASE_URL}/shot-video-prompted/generate-action-video/${dialogueId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            prompt: actionVideoPrompt
          }),
          signal, // Add the abort signal
        });
        
        // Clear the timeout since the request completed
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Failed to initiate action video generation');
        }
        
        const data = await response.json();
        
        // Start polling for the video generation result
        startVideoPolling();
      } catch (err) {
        // Special handling for timeout/abort errors
        if (err.name === 'AbortError') {
          console.log('Request timed out but may still be processing on the server');
          setVideoStatusMessage('Video generation may take longer. Polling for status...');
          // Start polling anyway, assuming the backend is still processing
          startVideoPolling();
        } else {
          // Handle other errors
          onError(`Failed to generate action video: ${err.message}`);
          setVideoStatusMessage(null);
        }
      }
    } catch (err) {
      onError(`Unexpected error: ${err.message}`);
      setVideoStatusMessage(null);
    } finally {
      setIsGeneratingVideo(false);
    }
  };

  return (
    <>
      {/* Video generation status message display */}
      {videoStatusMessage && (
        <div style={{
          padding: '10px',
          marginBottom: '15px',
          backgroundColor: 'rgba(0, 123, 255, 0.1)',
          borderRadius: '4px',
          color: '#007BFF',
          textAlign: 'center'
        }}>
          {videoStatusMessage}
        </div>
      )}

      {/* Generate Shot Video controls - only appears when there's an image URL */}
      {imageUrl && (
        <div style={styles.formGroup}>
          {/* Shot type selection */}
          <div style={{marginBottom: '15px'}}>
            <label style={styles.label}>Select shot type:</label>
            <select 
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#333',
                color: '#fff',
                border: '1px solid #555',
                borderRadius: '4px',
                fontSize: '16px'
              }}
              value={shotType}
              onChange={(e) => {
                const newType = e.target.value;
                setShotType(newType);
                
                // Call the parent handler to update the database if user has permissions
                if ((permissionLevel === 'owner' || permissionLevel === 'editor') && onShotTypeChange) {
                  onShotTypeChange(newType);
                }
              }}
              disabled={isGeneratingVideo || isPollingVideo}
            >
              <option value="talking">Talking Character</option>
              <option value="action">Action or Voiceover</option>
            </select>
          </div>
          
          {/* Action Video Prompt field - only shown for action shot type */}
          {shotType === 'action' && (
            <div style={styles.formGroup}>
              <div style={styles.editButtonContainer}>
                {permissionLevel !== 'viewer' && (
                  <button
                    onClick={() => {
                      setIsEditingActionVideoPrompt(!isEditingActionVideoPrompt);
                      setEditedActionVideoPrompt(actionVideoPrompt || '');
                    }}
                    style={{...styles.button, ...styles.editButton}}
                  >
                    <Pencil size={16} />
                  </button>
                )}
                <label style={styles.label}>Action Video Prompt:</label>
              </div>
              <div style={{ position: 'relative', width: '100%' }}>
                {isEditingActionVideoPrompt ? (
                  <>
                    <textarea
                      value={editedActionVideoPrompt}
                      onChange={handleActionVideoPromptChange}
                      style={{...styles.textarea, textAlign: 'left'}}
                      placeholder="Enter action video prompt"
                      rows={4}
                    />
                    <button onClick={handleActionVideoPromptSave} style={{...styles.button, marginTop: '10px'}}>
                      Save
                    </button>
                  </>
                ) : (
                  <div style={{
                    padding: '15px',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    borderRadius: '8px',
                    textAlign: 'left'
                  }}>
                    {actionVideoPrompt || 'No action video prompt set. Please add a prompt to generate an action video.'}
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Video generation button */}
          <button
            onClick={shotType === 'talking' ? handleGenerateShotVideo : handleGenerateActionVideo}
            style={{
              ...styles.button,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              padding: '10px 20px',
              backgroundColor: (isGeneratingVideo || isPollingVideo) ? '#555' : '#007BFF',
              opacity: (isGeneratingVideo || isPollingVideo) ? 0.7 : 1,
              cursor: (isGeneratingVideo || isPollingVideo || 
                     (shotType === 'talking' && !audioUrl && characterName !== "Non-speaking part") ||
                     (shotType === 'action' && !actionVideoPrompt)) ? 'not-allowed' : 'pointer'
            }}
            disabled={isGeneratingVideo || isPollingVideo || 
                     (shotType === 'talking' && !audioUrl && characterName !== "Non-speaking part") ||
                     (shotType === 'action' && !actionVideoPrompt)}
          >
            <Video size={16} />
            {isGeneratingVideo 
              ? 'Initiating...' 
              : isPollingVideo 
                ? 'Generating...' 
                : shotType === 'action'
                  ? 'Generate Action Video'
                  : 'Generate Talking Video'}
          </button>
        </div>
      )}
      
      {/* Display generated video with storage status if available */}
      {videoUrl && showVideo && (
        <div style={styles.formGroup}>
          <label style={styles.label}>
            Generated Video:
            {videoStorageStatus && videoStorageStatus !== 'completed' && videoStorageStatus !== 'audio_overlay_completed' && (
              <span style={{
                fontSize: '12px',
                marginLeft: '8px',
                color: '#FFA500',
                backgroundColor: 'rgba(255, 165, 0, 0.1)',
                borderRadius: '4px',
                padding: '2px 6px',
              }}>
                {videoStorageStatus === 'downloading' ? 'Downloading to Storage...' : 
                 videoStorageStatus === 'uploading' ? 'Uploading to Storage...' : 
                 videoStorageStatus === 'overlaying_audio' ? 'Adding Audio...' : videoStorageStatus}
              </span>
            )}
            {storedVideoUrl && (
              <span style={{
                fontSize: '12px',
                marginLeft: '8px',
                color: '#90EE90',
                backgroundColor: 'rgba(0, 100, 0, 0.1)',
                borderRadius: '4px',
                padding: '2px 6px',
              }}>
                Stored in Cloud
              </span>
            )}
          </label>
          <div style={{
            padding: '15px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <video
              key={`generated-video-${videoUrl}`} // Add a key to force re-render
              controls
              src={videoUrl}
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
                borderRadius: '4px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
              }}
              onError={(e) => {
                console.error('Video failed to load, retrying...');
                // If load fails, add a timestamp to retry loading
                setTimeout(() => {
                  e.target.src = `${videoUrl}?t=${Date.now()}`;
                }, 1000);
              }}
            >
              Your browser does not support the video element.
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default ShotVideo;