// src/pages/CharacterHomeScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil } from 'lucide-react';

const CharacterHomeScreen = () => {
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const { user } = useUser();
  const { characterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get referrer information from location state
  const referrer = location.state?.referrer;
  const storyId = location.state?.storyId;

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchCharacterDetails();
    } else {
      navigate('/login');
    }
  }, [characterId, user.isAuthenticated, navigate]);

  const fetchCharacterDetails = async () => {
    if (!user.user_id || !characterId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/character/${characterId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch character details');
      }

      const data = await response.json();
      setCharacter(data.character);
      setPermissionLevel(data.permissionLevel);
      setProjectId(data.projectId);
      setName(data.character.name || '');
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character/${characterId}/update-name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          name 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update name');
      }

      setCharacter({ ...character, name });
      setIsEditingName(false);
    } catch (err) {
      console.error('Error updating name:', err);
      setError('Failed to update name. Please try again.');
    }
  };

  const handleDeleteCharacter = async () => {
    if (window.confirm('Are you sure you want to delete this character?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/character/delete`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            user_id: user.user_id, 
            character_id: characterId,
            project_id: projectId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to delete character');
        }

        // If we came from script media screen, go back there
        if (referrer === 'scriptMedia' && storyId) {
          navigate(`/story/${storyId}/script-media`);
        } else {
          // Otherwise go to project page as before
          navigate(`/project/${projectId}`);
        }
      } catch (err) {
        console.error('Error deleting character:', err);
        setError('Failed to delete character. Please try again.');
      }
    }
  };

  // Determine where the back button should link to
  const getBackLink = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return `/story/${storyId}/script-media`;
    }
    return `/project/${projectId}`;
  };

  // Determine the back button text
  const getBackLinkText = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return <>&lt;&lt; Back to Script Media</>;
    }
    return <>&lt;&lt; Back to Project Home</>;
  };

  // Pass referrer info to nested paths
  const getVoiceLink = () => {
    return {
      pathname: `/character-voice/${characterId}`,
      state: { referrer, storyId }
    };
  };

  const getImageLink = () => {
    return {
      pathname: `/character-image/${characterId}`,
      state: { referrer, storyId }
    };
  };

  if (isLoading) return <div style={styles.container}>Loading character...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!character) return <div style={styles.container}>Character not found.</div>;

  const canEdit = permissionLevel === 'owner' || permissionLevel === 'editor';

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={getBackLink()} style={styles.link}>
          {getBackLinkText()}
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        {character.name || 'Unnamed Character'}
      </h1>

      <div style={styles.formGroup}>
        <label style={styles.label}>Created By:</label>
        <p style={{...styles.projectName, textAlign: 'left'}}>
          {character.created_by || 'Unknown'}
        </p>
      </div>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {canEdit && (
            <button
              onClick={() => setIsEditingName(!isEditingName)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Character Name:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingName && canEdit ? (
            <>
              <input
                value={name}
                onChange={handleNameChange}
                style={{...styles.formInput, textAlign: 'left'}}
                placeholder="Enter character name"
              />
              <button onClick={handleNameSave} style={{...styles.button, marginTop: '10px'}}>
                Save
              </button>
            </>
          ) : (
            <p style={{...styles.projectName, textAlign: 'left'}}>
              {character.name || 'Unnamed Character'}
            </p>
          )}
        </div>
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Voice Generation:</label>
        <Link 
          to={getVoiceLink()}
          style={{
            ...styles.storyLink,
            display: 'block',
            textAlign: 'left',
          }}
        >
          {character.name}'s Voice
        </Link>
      </div>

      <div style={{...styles.formGroup, alignItems: 'flex-start'}}>
        <label style={{...styles.label, marginBottom: '10px'}}>Image Generation:</label>
        <Link 
          to={getImageLink()}
          style={{
            ...styles.storyLink,
            display: 'block',
            textAlign: 'left',
          }}
        >
          {character.name}'s Image
        </Link>
      </div>

      {canEdit && (
        <div style={styles.projectActions}>
          <button 
            style={{...styles.button, backgroundColor: '#ff4444'}}
            onClick={handleDeleteCharacter}
          >
            Delete Character
          </button>
        </div>
      )}
    </div>
  );
};

export default CharacterHomeScreen;