// src/components/GenerateScriptAudio.js

import React, { useState } from 'react';
import { Loader } from 'lucide-react';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const GenerateScriptAudio = ({ storyId, userId, onUpdate }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [statusInfo, setStatusInfo] = useState('');

  const startAudioGeneration = async () => {
    setIsGenerating(true);
    setError(null);
    
    try {
      // Start generation process
      const response = await fetch(`${API_BASE_URL}/script-audio/start-generation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          story_id: storyId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start audio generation');
      }

      // Set up SSE connection for progress updates
      const eventSource = new EventSource(
        `${API_BASE_URL}/script-audio/progress/${storyId}?user_id=${userId}`
      );
      
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received SSE update:', data);

          if (data.complete) {
            console.log('Generation complete, closing event source');
            eventSource.close();
            setIsGenerating(false);
            onUpdate({ complete: true });
            setStatusInfo('All audio has been generated successfully.');
            return;
          }

          if (data.error) {
            console.error('Error in SSE:', data.error);
            setError(data.error);
            eventSource.close();
            setIsGenerating(false);
            return;
          }

          // Pass the update to parent component through the callback
          onUpdate(data);

          // Update status info
          setStatusInfo(`Generating audio for line ${data.line_number}: ${data.progress}% complete`);
        } catch (err) {
          console.error('Error processing SSE message:', err);
        }
      };

      eventSource.onerror = (err) => {
        console.error('SSE error:', err);
        eventSource.close();
        setIsGenerating(false);
        setError('Lost connection to server');
      };

    } catch (err) {
      console.error('Error starting generation:', err);
      setError(err.message);
      setIsGenerating(false);
    }
  };

  // Helper to get status info text
  const getGenerationStatusInfo = () => {
    if (error) {
      return `Error: ${error}`;
    }
    
    if (isGenerating) {
      return statusInfo || 'Generating audio...';
    }
    
    return '';
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px'
    }}>
      <button
        onClick={startAudioGeneration}
        style={{
          ...styles.button,
          backgroundColor: '#007BFF',
          fontSize: '1.1rem',
          padding: '12px 24px',
          opacity: isGenerating ? 0.7 : 1,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '10px'
        }}
        disabled={isGenerating}
      >
        {isGenerating && <Loader size={16} />}
        {isGenerating ? 'Generating Audio...' : 'Generate Script Audio'}
      </button>
      
      {/* Status info indicator */}
      {getGenerationStatusInfo() && (
        <div style={{
          fontSize: '0.9rem',
          color: error ? '#FF4444' : '#AAA',
          textAlign: 'center'
        }}>
          {getGenerationStatusInfo()}
        </div>
      )}
    </div>
  );
};

export default GenerateScriptAudio;