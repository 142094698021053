// src/pages/CharacterImageScreen.js

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, Image as ImageIcon } from 'lucide-react';

const CharacterImageScreen = () => {
  // State variables for managing UI and data
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [description, setDescription] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  // Hooks for navigation, user context, and parameters
  const { user } = useUser();
  const { characterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Get referrer information from location state
  const referrer = location.state?.referrer;
  const storyId = location.state?.storyId;

  // Refs to store interval and timeout IDs for cleanup
  const pollIntervalRef = useRef(null);
  const pollTimeoutRef = useRef(null);

  // Fetch character details on mount or when characterId/user changes
  useEffect(() => {
    if (user.isAuthenticated) {
      fetchCharacterDetails();
    } else {
      navigate('/login');
    }
  }, [characterId, user.isAuthenticated, navigate]);

  // Cleanup intervals and timeouts when component unmounts
  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) clearInterval(pollIntervalRef.current);
      if (pollTimeoutRef.current) clearTimeout(pollTimeoutRef.current);
    };
  }, []);

  // Function to fetch character details from the back-end
  const fetchCharacterDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/details?user_id=${user.user_id}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch character details');
      }
      const data = await response.json();
      setCharacter(data.character);
      setDescription(data.imageDescription || '');
      setPermissionLevel(data.permissionLevel);
      setProjectId(data.projectId);
      if (data.character.character_image_url) {
        setGeneratedImage(data.character.character_image_url);
      }
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  // Handle changes to the description input
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Save the updated description to the back-end
  const handleDescriptionSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/update-description`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          description: description,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update description');
      }

      setIsEditingDescription(false);
      fetchCharacterDetails();
    } catch (err) {
      setError('Failed to update description: ' + err.message);
    }
  };

  // Start polling for the generated image URL
  const startPolling = () => {
    setIsPolling(true);
    setStatusMessage('Waiting for image to generate...');
  
    pollIntervalRef.current = setInterval(async () => {
      try {
        const detailsResponse = await fetch(
          `${API_BASE_URL}/character-image/${characterId}/details?user_id=${user.user_id}`
        );
        if (!detailsResponse.ok) {
          throw new Error('Failed to fetch character details');
        }
  
        const detailsData = await detailsResponse.json();
        const characterData = detailsData.character;
        const imageUrl = characterData && characterData.character_image_url;
  
        // Compare with current image URL to detect changes
        if (imageUrl && imageUrl !== generatedImage) {
          clearInterval(pollIntervalRef.current);
          clearTimeout(pollTimeoutRef.current);
          setGeneratedImage(imageUrl);
          setCharacter({
            ...character,
            ...characterData
          });
          setIsPolling(false);
          setStatusMessage(null);
          
          // Force a refresh by creating a temporary loading state
          setGeneratedImage(null);
          setTimeout(() => {
            setGeneratedImage(imageUrl);
          }, 50);
        }
      } catch (err) {
        console.error('Error polling for image URL:', err);
        // Continue polling despite errors
      }
    }, 3000); // Poll every 3 seconds
  
    pollTimeoutRef.current = setTimeout(() => {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
      setError('Image generation took too long. Please try again later.');
    }, 300000); // 5 minutes
  };

  // Handle image generation initiation
  const handleGenerateImage = async () => {
    if (!description) {
      setError('Please provide a character description before generating an image');
      return;
    }

    try {
      setIsGenerating(true);
      setError(null);
      setStatusMessage('Initiating image generation...');
      
      // Clear current image to ensure state update when new one arrives
      setGeneratedImage(null);

      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30-second timeout

      try {
        const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/generate-image`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.user_id, project_id: projectId }),
          signal,
        });
        clearTimeout(timeoutId);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to initiate image generation');
        }

        // Start polling after successful POST
        startPolling();
      } catch (err) {
        if (err.name === 'AbortError') {
          // If POST times out, start polling anyway (assuming back-end continues)
          setStatusMessage('Image generation may take longer. Polling for status...');
          startPolling();
        } else {
          setError('Failed to initiate image generation: ' + err.message);
        }
      }
    } catch (err) {
      setError('Unexpected error: ' + err.message);
    } finally {
      setIsGenerating(false);
    }
  };

  // Determine where the back button should link to
  const getBackLink = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return `/story/${storyId}/script-media`;
    }
    return `/character/${characterId}`;
  };

  // Determine the back button text
  const getBackLinkText = () => {
    if (referrer === 'scriptMedia' && storyId) {
      return <>&lt;&lt; Back to Script Media</>;
    }
    return <>&lt;&lt; Back to Character</>;
  };

  // Render loading or error states
  if (isLoading) return <div style={styles.container}>Loading character...</div>;
  if (!character) return <div style={styles.container}>Character not found.</div>;

  const canEdit = permissionLevel === 'owner' || permissionLevel === 'editor';

  // Main render
  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={getBackLink()} style={styles.link}>
          {getBackLinkText()}
        </Link>
      </div>
      {statusMessage && <p style={{ color: 'blue', textAlign: 'center' }}>{statusMessage}</p>}
      {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
      <h1 style={{ ...styles.formTitle, textAlign: 'center', marginBottom: '30px' }}>
        Character Images for {character.name}
      </h1>
      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {canEdit && (
            <button
              onClick={() => setIsEditingDescription(!isEditingDescription)}
              style={{ ...styles.button, ...styles.editButton }}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Image Description:</label>
        </div>
        {isEditingDescription && canEdit ? (
          <>
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              style={{ ...styles.textarea, textAlign: 'left' }}
              placeholder="Enter image description"
            />
            <button onClick={handleDescriptionSave} style={{ ...styles.button, marginTop: '10px' }}>
              Save
            </button>
          </>
        ) : (
          <p style={{ ...styles.projectName, textAlign: 'left' }}>
            {description || 'No description provided.'}
          </p>
        )}
      </div>
      {canEdit && description && (
        <div style={styles.formGroup}>
          <button
            onClick={handleGenerateImage}
            disabled={isGenerating || isPolling}
            style={{
              ...styles.button,
              backgroundColor: '#007BFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              margin: '20px auto',
              opacity: isGenerating || isPolling ? 0.7 : 1,
              cursor: isGenerating || isPolling ? 'not-allowed' : 'pointer',
            }}
          >
            <ImageIcon size={18} />
            {isGenerating ? 'Initiating...' : isPolling ? 'Generating...' : 'Generate Character Image'}
          </button>
        </div>
      )}
      {generatedImage && (
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 style={{ ...styles.formTitle, fontSize: '1.2rem', marginBottom: '15px' }}>
            Generated Character Image
          </h2>
          <div style={{ border: '1px solid #444', borderRadius: '8px', padding: '10px', backgroundColor: 'rgba(0,0,0,0.2)', maxWidth: '500px' }}>
            <img 
              src={`${generatedImage}?t=${Date.now()}`} 
              alt={`Generated image of ${character.name}`} 
              style={{ maxWidth: '100%', borderRadius: '4px' }} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CharacterImageScreen;