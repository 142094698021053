// src/components/GenerateShotTypes.js
import React, { useState } from 'react';
import { Loader } from 'lucide-react';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const GenerateShotTypes = ({ storyId, userId, onGenerationComplete }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  const generateShotTypes = async () => {
    setIsGenerating(true);
    setError(null);
    setStats(null);
    
    try {
      const response = await fetch(`${API_BASE_URL}/script-shot-types/generate/${storyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate shot types');
      }

      const data = await response.json();
      setStats(data.stats);
      
      // Call the callback to refresh data
      if (onGenerationComplete) {
        onGenerationComplete();
      }
    } catch (err) {
      console.error('Error generating shot types:', err);
      setError(err.message);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
      marginTop: '20px'
    }}>
      <button
        onClick={generateShotTypes}
        style={{
          ...styles.button,
          backgroundColor: '#007BFF', // Blue color to match other buttons
          fontSize: '1.1rem',
          padding: '12px 24px',
          opacity: isGenerating ? 0.7 : 1,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '10px'
        }}
        disabled={isGenerating}
      >
        {isGenerating && <Loader size={16} />}
        {isGenerating ? 'Generating Shot Types...' : 'Generate Shot Types'}
      </button>
      
      {/* Error message */}
      {error && (
        <div style={{
          fontSize: '0.9rem',
          color: '#FF4444',
          textAlign: 'center',
          marginBottom: '10px'
        }}>
          Error: {error}
        </div>
      )}
      
      {/* Results stats */}
      {stats && (
        <div style={{
          fontSize: '0.9rem',
          color: '#4CAF50',
          textAlign: 'center',
          padding: '10px',
          backgroundColor: 'rgba(76,175,80,0.1)',
          borderRadius: '4px',
          marginBottom: '10px'
        }}>
          Updated {stats.updated_lines} script lines: {' '}
          {stats.talking_count} talking shots and {stats.action_count} action shots.
        </div>
      )}
    </div>
  );
};

export default GenerateShotTypes;