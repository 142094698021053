// src/components/ShotMediaTable.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { Play, AlertTriangle } from 'lucide-react';

const ShotMediaTable = ({ storyId, parsedLines, audioStatus, isAudioInitialized }) => {
  const [error, setError] = useState(null);
  const [currentLine, setCurrentLine] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  // Debug logging - check the structure of parsedLines
  useEffect(() => {
    console.log('ShotMediaTable - parsedLines:', parsedLines);
  }, [parsedLines]);

  const playAudio = async (lineId) => {
    if (!audioStatus[lineId]?.url) {
      console.error('No audio URL for line', lineId);
      return;
    }

    try {
      // Stop any currently playing audio
      if (currentLine !== null) {
        setCurrentLine(null);
      }

      console.log('Playing audio for line', lineId, 'URL:', audioStatus[lineId].url);
      const audio = new Audio(audioStatus[lineId].url);
      
      // Ensure audio is loaded before playing
      await audio.load();
      
      audio.onerror = (e) => {
        console.error('Audio error:', e);
        setError(`Failed to play audio for line ${lineId}`);
        setCurrentLine(null);
      };
      
      audio.onended = () => {
        console.log('Audio playback complete for line', lineId);
        setCurrentLine(null);
      };
      
      setCurrentLine(lineId);
      await audio.play();
    } catch (err) {
      console.error('Error playing audio:', err);
      setError(`Failed to play audio for line ${lineId}`);
      setCurrentLine(null);
    }
  };

  const handleRowClick = (parsedLine) => {
    console.log('Row clicked, parsedLine:', parsedLine);
    
    if (parsedLine && parsedLine.id) {
      console.log('Navigating to dialog ID:', parsedLine.id);
      navigate(`/shot/${parsedLine.id}`);
    } else {
      console.error('Missing ID in parsedLine:', parsedLine);
      setError('Cannot view shot details: Missing line ID');
    }
  };

  const renderProgressOrPlayButton = (line) => {
    const status = audioStatus[line.line_number];
    
    if (!status) {
      return null;
    }

    // If there's a URL, show play button
    if (status.url) {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click when clicking the play button
            playAudio(line.line_number);
          }}
          style={{
            ...styles.button,
            padding: '4px 8px',
            minWidth: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: currentLine === line.line_number ? '#666' : '#007BFF'
          }}
          disabled={currentLine === line.line_number}
        >
          <Play size={16} />
        </button>
      );
    }

    // If status is error, show error indicator
    if (status.status === 'error') {
      return (
        <div title={status.message || 'Error generating audio'} style={{
          display: 'flex',
          alignItems: 'center',
          color: '#FF4444'
        }}>
          <AlertTriangle size={16} />
          <span style={{ marginLeft: '4px', fontSize: '0.7rem' }}>Error</span>
        </div>
      );
    }

    // If generating, show progress
    if (status.status === 'generating') {
      return (
        <div style={{ position: 'relative', width: '40px', height: '20px' }}>
          <div style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '4px',
            backgroundColor: '#333',
            borderRadius: '2px',
          }}>
            <div style={{
              width: `${status.progress}%`,
              height: '100%',
              backgroundColor: '#007BFF',
              borderRadius: '2px',
              transition: 'width 0.3s ease'
            }} />
          </div>
          <span style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '0.7rem',
            color: '#fff'
          }}>
            {status.progress}%
          </span>
        </div>
      );
    }

    return null;
  };

  // Helper function to format shot type for display
  const formatShotType = (shotType) => {
    if (!shotType || shotType === 'None') return 'None';
    return shotType.charAt(0).toUpperCase() + shotType.slice(1);
  };

  if (error) return <div style={styles.error}>{error}</div>;
  if (!isAudioInitialized) return <div>Loading audio status...</div>;

  // Mimic the same table styling used in ScriptMediaScreen
  const tableContainerStyle = {
    overflowX: 'auto',
    width: '100%',
    marginBottom: '40px'
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    backgroundColor: 'rgba(255,255,255,0.05)',
    tableLayout: 'fixed'
  };

  const headerStyle = {
    padding: '12px',
    textAlign: 'left',
    borderBottom: '2px solid #444',
    backgroundColor: '#333'
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={styles.formTitle}>Shot List</h2>
      
      <div style={tableContainerStyle}>
        <table style={tableStyle}>
          <colgroup>
            <col style={{ width: '4%' }} /> {/* Line */}
            <col style={{ width: '10%' }} /> {/* Scene */}
            <col style={{ width: '8%' }} /> {/* Character */}
            <col style={{ width: '20%' }} /> {/* Dialogue */}
            <col style={{ width: '5%' }} /> {/* Audio */}
            <col style={{ width: '20%' }} /> {/* Image Prompt */}
            <col style={{ width: '5%' }} /> {/* Image */}
            <col style={{ width: '5%' }} /> {/* Shot Type */}
            <col style={{ width: '18%' }} /> {/* Action Video Prompt */}
            <col style={{ width: '5%' }} /> {/* Video */}
          </colgroup>
          <thead>
            <tr>
              <th style={{...headerStyle, whiteSpace: 'nowrap'}}>Line</th>
              <th style={headerStyle}>Scene</th>
              <th style={headerStyle}>Character</th>
              <th style={headerStyle}>Dialogue</th>
              <th style={headerStyle}>Audio</th>
              <th style={headerStyle}>Image Prompt</th>
              <th style={headerStyle}>Image</th>
              <th style={headerStyle}>Shot Type</th>
              <th style={headerStyle}>Action Video Prompt</th>
              <th style={headerStyle}>Video</th>
            </tr>
          </thead>
          <tbody>
            {parsedLines.map((line) => (
              <tr 
                key={line.line_number} 
                style={{
                  backgroundColor: line.character_name === "Non-speaking part" ? 'rgba(255,255,255,0.03)' : undefined,
                  cursor: 'pointer',
                  transition: 'background-color 0.2s ease'
                }}
                onClick={() => handleRowClick(line)}
              >
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  whiteSpace: 'nowrap'
                }}>{line.line_number}</td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  color: line.scene_location ? '#aaa' : '#666',
                  wordBreak: 'break-word'
                }} title={line.scene_location}>
                  {line.scene_location || 'N/A'}
                </td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  fontStyle: line.character_name === "Non-speaking part" ? 'italic' : 'normal',
                  color: line.character_name === "Non-speaking part" ? '#888' : undefined,
                  wordBreak: 'break-word'
                }}>{line.character_name}</td>
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  fontStyle: line.character_name === "Non-speaking part" ? 'italic' : 'normal',
                  color: line.character_name === "Non-speaking part" ? '#888' : undefined,
                  wordBreak: 'break-word'
                }}>{line.dialogue_text}</td>
                
                <td style={{
                  padding: '12px',
                  borderBottom: '1px solid #444',
                  textAlign: 'center'
                }} onClick={(e) => e.stopPropagation()}>
                  {renderProgressOrPlayButton(line)}
                </td>
                
                <td 
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #444',
                    maxHeight: '120px',
                    overflow: 'auto',
                    wordBreak: 'break-word'
                  }} 
                  title={line.image_prompt}
                  onClick={(e) => e.stopPropagation()}
                >
                  {line.image_prompt || 'None'}
                </td>
                
                <td 
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #444',
                    textAlign: 'center'
                  }} 
                  onClick={(e) => e.stopPropagation()}
                >
                  {line.stored_image_url ? (
                    <a 
                      href={line.stored_image_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{
                        ...styles.link,
                        padding: '2px 8px',
                        backgroundColor: 'rgba(0, 123, 255, 0.1)',
                        borderRadius: '4px',
                        fontSize: '0.8rem'
                      }}
                    >
                      View
                    </a>
                  ) : (
                    <span style={{ color: '#666' }}>None</span>
                  )}
                </td>
                
                <td 
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #444',
                    wordBreak: 'break-word'
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {formatShotType(line.shot_type)}
                </td>
                
                <td 
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #444',
                    maxHeight: '120px',
                    overflow: 'auto',
                    wordBreak: 'break-word'
                  }} 
                  title={line.action_video_prompt}
                  onClick={(e) => e.stopPropagation()}
                >
                  {line.action_video_prompt || 'None'}
                </td>
                
                <td 
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #444',
                    textAlign: 'center'
                  }} 
                  onClick={(e) => e.stopPropagation()}
                >
                  {line.video_url ? (
                    <a 
                      href={line.video_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{
                        ...styles.link,
                        padding: '2px 8px',
                        backgroundColor: 'rgba(0, 123, 255, 0.1)',
                        borderRadius: '4px',
                        fontSize: '0.8rem'
                      }}
                    >
                      View
                    </a>
                  ) : (
                    <span style={{ color: '#666' }}>None</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShotMediaTable;